import React, { useState } from 'react'
import { useFirestore } from 'reactfire'
import * as STATUS from 'constants/status'
import { db } from 'fb'

import {
  Container,
  Paper,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Avatar,
  Divider
} from '@material-ui/core'
import DatePicker from 'react-datepicker'
import { newOfferNotification } from 'slack';

const Estado = ({ employer, updateData, adalabers }) => {
  console.log('!', employer)
  const OpportunityRef = useFirestore()
    .collection(employer.collection)
    .doc(employer.employer)
    .collection('opportunities')
    .doc(employer.id)

  let [status, setStatus] = useState(employer.status)
  let [statusHistory] = useState(employer.statusHistory || [])
  let [acceptanceDeadline, setAcceptanceDeadline] = useState(() =>
    employer.acceptanceDeadline
      ? new Date(Date.parse(employer.acceptanceDeadline))
      : ''
  )
  let [noHiringReason, setNoHiringReason] = useState(employer.noHiringReason)

  const handleDateChange = date => {
    setAcceptanceDeadline(date)
  }

  const onSubmit = async e => {
    e.preventDefault()
    // (updateData, adalabers) => (employer, newFields) =>
    // update(updateData, adalabers)(employer, { ...estado })
    const newFields = {
      status,
      statusHistory,
      acceptanceDeadline,
      ...(noHiringReason && { noHiringReason })
    }

    if (status) {
      newFields.statusHistory.push({
        status: status,
        timestamp: new Date().toISOString()
      })

      if (acceptanceDeadline)
        newFields.acceptanceDeadline = acceptanceDeadline.toISOString()
    }

    const prod =
      !window.location.href.includes('netlify') &&
      !window.location.href.includes('localhost') &&
      !window.location.href.includes('emma-demo')

    if (status === STATUS.VALIDATED) {
      if (prod) {
        newOfferNotification(
          {
            companyName: employer.name,
            contractType: employer.contractType,
            salaryRange: employer.salaryRange,
            workingMode: employer.workingMode,
            workingModeOther: employer.workingModeOther,
            employerId: employer.id,
            jobLocation: employer.location,
            acceptanceDeadline: acceptanceDeadline.toISOString()
          }
        )
      }
    }

    await OpportunityRef.update(newFields)

    if (newFields.status === STATUS.DEACTIVATED) {
      await db.removeEmployerDeactivated(adalabers, employer)
    }
  }

  if (!employer) return null
  return (
    <Container maxWidth="sm">
      <Paper square>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={2}
        >
          {employer && employer.logo && (
            <Avatar style={{ width: 60, height: 60 }}>
              <img src={employer.logo} alt={employer.name} />
            </Avatar>
          )}
          <Typography component="h5" variant="h5">
            Oferta {status}
          </Typography>
        </Box>

        <Box
          component="form"
          onSubmit={onSubmit}
          p={2}
          display="flex"
          alignItems="stretch"
          justifyContent="center"
          className="distribute"
        >
          <FormControl>
            <Select
              value={status}
              onChange={e => setStatus(e.target.value)}
              inputProps={{
                name: 'status',
                id: 'status'
              }}
              className="select button"
              variant="outlined"
              label="estado"
              id="estado"
            >
              <MenuItem value="">
                <em>Sin Estado</em>
              </MenuItem>
              {STATUS.ALL.map(state => (
                <MenuItem
                  value={state}
                  key={state}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {status === STATUS.VALIDATED && (
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={acceptanceDeadline}
              onChange={handleDateChange}
              id="acceptanceDeadline"
              showTimeSelect
              customInput={
                <TextField variant="outlined" label="Deadline aceptación" />
              }
            />
          )}

          {status === STATUS.FINISHED_NO_HIRING && (
            <TextField
              label="Razón de no contratación"
              name="noHiringReason"
              value={noHiringReason}
              onChange={e => setNoHiringReason(e.target.value)}
              className="button"
              multiline
            />
          )}

          <Button variant="outlined" color="primary" type="submit">
            Actualizar
          </Button>
        </Box>

        {employer.statusHistory && (
          <>
            <Divider />

            <Box p={2}>
              <Typography component="h2" variant="h6">
                Histórico de estados
              </Typography>

              <List>
                {employer.statusHistory
                  .sort((a, b) =>
                    new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1
                  )
                  .map(state => (
                    <ListItem key={state.timestamp}>
                      <ListItemText
                        primary={state.status}
                        secondary={new Date(state.timestamp).toLocaleString(
                          'es'
                        )}
                      />
                    </ListItem>
                  ))}
              </List>
            </Box>
          </>
        )}
      </Paper>
    </Container>
  )
}

export default Estado
