import React from 'react'
import PropTypes from 'prop-types'
import { db } from 'fb'
import moment from 'moment'

import { Link } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import * as routes from 'constants/routes'
import TooltipWithList from '../common/TooltipWithList'
import ContratarFromAdalaber from './ContratarFromAdalaber'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
} from '@material-ui/core'

import { CheckCircle as CheckCircleIcon, Phone as PhoneIcon, Work as WorkIcon, Undo, NotInterested, PowerSettingsNew, CalendarToday } from '@material-ui/icons'
import SmilingGirl from 'components/UI/CustomIcons/SmilingGirl'
import HandShake from 'components/UI/CustomIcons/HandShake'
import { PromoAvatar } from 'components/UI/common'

export const SimpleTable = ({ adalabers, employers, changeOrder, orderBy, desc, update }) => {
  const [activeAdalaber, setActiveAdalaber] = React.useState(null)
  const [deactivateReason, setDeactivateReason] = React.useState("")

  const setAndOpen = (id) => {
    const adalaber = adalabers.find(adalaber => adalaber.id === id)
    Promise.resolve(setActiveAdalaber(adalaber)).then(() => showHandshakeModal())
  }

  const [showHandshakeModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Dialog className="modalWithDatepicker" maxWidth="xs" open={open} onExited={onExited} onClose={hideModal}>
        <ContratarFromAdalaber hideModal={hideModal} update={update} adalaber={activeAdalaber} employers={employers} onUnsubscribe={async() => {
          const fullEmployer = employers.find(emp => emp.id === activeAdalaber.employer)
          await db.removeAdalaberFromEmployer(fullEmployer, activeAdalaber)
          hideModal()
        }} />
      </Dialog>
    ), [activeAdalaber]
  )


  const reactivate = adalaber => async event => {
    event.preventDefault();
    await db.reactivateAdalaber(adalaber)
  };

  const dir = desc ? 'desc' : 'asc'

  const [showDeactivateModal, hideDeactivateModal] = useModal(
    ({ in: open, onExited }) => {

      return (
        <Dialog maxWidth="xs" open={open} onExited={onExited} onClose={hideDeactivateModal}>
          <DialogContent>
            <h2>Vas a desactivar a la adalaber</h2>
            <FormControl style={{ marginTop: "2rem", width: "100%" }}>
              <TextField
                multiline
                rows={4}
                value={deactivateReason}
                onChange={(e) => setDeactivateReason(e.target.value)}
                variant="outlined"
                fullWidth id="employerOutOfNetwork" label="Explica los motivos" autoFocus />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={hideDeactivateModal}>
              Cancelar
        </Button>
            <Button
              onClick={() => {
                db.deactivateAdalaber(activeAdalaber, deactivateReason);
                setDeactivateReason("")
                hideDeactivateModal()
              }} type="submit" color="primary"
            >
              Desactivar
        </Button>
          </DialogActions>
        </Dialog>
      )
    }, [deactivateReason, activeAdalaber]
  )

  return (
    <Box py={2}>
      <Paper>
        <TableContainer>
          <Table className="table">
            <TableHead className="stickyHeader">
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'fullName'}
                    direction={dir}
                    onClick={changeOrder('fullName')}
                  >
                    Adalaber
                </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'accepted'}
                    direction={dir}
                    onClick={changeOrder('accepted')}
                  >
                    <Tooltip title="Empresas que ha aceptado">
                      <CheckCircleIcon />
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'contacted'}
                    direction={dir}
                    onClick={changeOrder('contacted')}
                  >
                    <Tooltip title="Empresas que la han contactado">
                      <PhoneIcon />
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'employerName'}
                    direction={dir}
                    onClick={changeOrder('employerName')}
                  >
                    <Tooltip title="Empresa que la ha contratado">
                      <WorkIcon />
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'incorporationDate'}
                    direction={dir}
                    onClick={changeOrder('incorporationDate')}
                  >
                    <Tooltip title="Fecha de contratación">
                      <CalendarToday />
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adalabers.map((adalaber, i) => {
                // console.log(adalaber.fullName, adalaber, i)
                const { id, fullName, accepted, contacted, employerName, incorporationDate } = adalaber
                // const style = tableRowTrail[i]; (añadir style={style} a TableRow)

                return (
                  <TableRow key={id || i} className={adalaber.inactive ? 'inactive' : incorporationDate ? 'contratada' : ''}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Tooltip title={adalaber.promo}>
                          <PromoAvatar>{adalaber.promo[0]}</PromoAvatar>
                        </Tooltip>
                        {fullName}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <TooltipWithList list={accepted} />
                    </TableCell>
                    <TableCell>
                      <TooltipWithList list={contacted} />
                    </TableCell>

                    <TableCell>
                      {employerName && (<span>{employerName} </span>)}
                    </TableCell>

                    <TableCell>
                      {
                        incorporationDate && <span>{moment(incorporationDate).format('DD/MM/YYYY')}</span>
                      }
                    </TableCell>

                    <TableCell align="right">
                      <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Ver adalaber">
                          <IconButton component={Link} to={`${routes.ADALABERS}/${id}`}>
                            <SmilingGirl />
                          </IconButton>
                        </Tooltip>

                        {
                          (!adalaber.employer && !adalaber.employerOutOfNetwork && !adalaber.incorporationDate)
                            ? (
                              <Tooltip title="Insertar">
                                <IconButton onClick={() => setAndOpen(id)} aria-label="Insertar">
                                  <HandShake />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Editar inserción">
                                <IconButton onClick={() => {
                                  setActiveAdalaber(adalaber)
                                  setAndOpen(id)
                                }} aria-label="Editar inserción">
                                  <Undo />
                                </IconButton>
                              </Tooltip>
                            )
                        }

                        {adalaber.inactive ? (
                          <Tooltip title="Reactivar">
                            <IconButton onClick={reactivate(adalaber)} aria-label="Reactivar">
                              <PowerSettingsNew />
                            </IconButton>
                          </Tooltip>
                        ) : (
                            <Tooltip title="Desactivar">
                              <IconButton onClick={
                                () => {
                                  setActiveAdalaber(adalaber)
                                  showDeactivateModal()
                                }
                              } aria-label="Desactivar">
                                <NotInterested />
                              </IconButton>
                            </Tooltip>
                          )
                        }

                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export const ListWithAction = ({ adalabers, action, employer }) => {
  const totalAdalabers = field =>
    adalabers.filter(a => a[field] && a[field].includes(employer.id)).length;
  return (
    <Table className="table">
      <TableHead>
        <TableRow>
          <TableCell>Adalaber</TableCell>
          <TableCell>Aceptada ({totalAdalabers('accepted')})</TableCell>
          <TableCell>Contactada ({totalAdalabers('contacted')})</TableCell>
          <TableCell>Contratada ({totalAdalabers('employer')})</TableCell>
          <TableCell>Acción</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {adalabers.map(adalaber => {
          const {
            id,
            fullName,
            accepted,
            contacted,
            employer: currentEmployer,
          } = adalaber;

          return (
            <TableRow key={id} hover className="no-pointer">
              <TableCell>{fullName}</TableCell>
              <TableCell>
                {accepted && accepted.includes(employer.id) ? (
                  <CheckCircleIcon />
                ) : null}
              </TableCell>
              <TableCell>
                {contacted && contacted.includes(employer.id) ? (
                  <PhoneIcon />
                ) : null}
              </TableCell>
              <TableCell>
                {currentEmployer && currentEmployer === employer.id ? (
                  <WorkIcon />
                ) : null}
              </TableCell>

              <TableCell>
                <Button
                  onClick={action(
                    !!currentEmployer && currentEmployer === employer.id
                  ).func(adalaber)}
                  variant="outlined"
                  color="primary"
                >
                  {
                    action(!!currentEmployer && currentEmployer === employer.id)
                      .name
                  }
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

ListWithAction.propTypes = {
  adalabers: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.func.isRequired,
  employer: PropTypes.object.isRequired,
};

export class FilteredListWithAction extends React.Component {
  state = {
    showAll: '',
  };

  handleChange = event => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked ? 'checked' : '' });
  };

  render() {
    const { adalabers, employer, action } = this.props;
    const { showAll } = this.state;

    const filteredAdalabers = adalabers.filter(
      a =>
        showAll ||
        !((a.employer && a.employer !== employer.id) || a.employerOutOfNetwork)
    );
    return (
      <div className="container">
        <Toolbar>
          <form action="" className="search-form">
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAll}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'showAll',
                  }}
                />
              }
              label="Mostrar alumnas insertadas"
            />
          </form>
        </Toolbar>
        <ListWithAction
          employer={employer}
          adalabers={filteredAdalabers}
          action={action}
        />
      </div>
    );
  }
}

FilteredListWithAction.propTypes = {
  adalabers: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.func.isRequired,
  employer: PropTypes.object.isRequired,
};
