export const NEW = 'Nueva'
export const VALIDATED = 'Validada'
export const PLAZO_CERRADO = 'Plazo cerrado'
export const IN_PROCESS = 'Activa'
export const FINISHED_SUCCESS = 'Cerrado con éxito'
export const FINISHED_NO_HIRING = 'Cerrado sin contratación'
export const DEACTIVATED = 'Desactivada'

export const ALL = [
  NEW,
  VALIDATED,
  PLAZO_CERRADO,
  IN_PROCESS,
  FINISHED_SUCCESS,
  FINISHED_NO_HIRING,
  DEACTIVATED,
]

export const CAN_BE_CONTACTED = [PLAZO_CERRADO, IN_PROCESS]

export const OPPORTUNITIES_AVAILABLE = [
  VALIDATED,
  PLAZO_CERRADO,
  IN_PROCESS,
]

export const FINISHED = [FINISHED_SUCCESS, FINISHED_NO_HIRING, DEACTIVATED]
export const ALL_BUT_FINISHED = [NEW, VALIDATED, PLAZO_CERRADO, IN_PROCESS]