export default {
  current_promo: 'nerea',
  last_promo: 'mileva',
  lastDayOfPromotion: '2021-10-21',
  admittedAdalabers: 40,
  oldpromos: [
    'kizzy',
    'jemison',
    'idelisa',
    'hamilton',
    'grace',
    'fairfax',
    'easley'
  ],
  veryOldpromos: ['grace', 'fairfax', 'easley'],
  oldPromoDates: {
    nerea: {
      lastDayOfPromotion: '2021-10-21'
    },
    mileva: {
      lastDayOfPromotion: '2021-06-30'
    },
    lee: {
      lastDayOfPromotion: '2021-03-18'
    },
    kizzy: {
      lastDayOfPromotion: '2020-11-30'
    },
    jemison: {
      lastDayOfPromotion: '2020-08-13'
    },
    idelisa: {
      lastDayOfPromotion: '2020-04-29'
    },
    hamilton: {
      lastDayOfPromotion: '2020-01-17'
    },
    grace: {
      lastDayOfPromotion: '2019-10-07'
    },
    fairfax: {
      lastDayOfPromotion: '2019-06-06'
    },
    easley: {
      lastDayOfPromotion: '2019-02-20'
    }
  },
  isOldPromo(promo) {
    return this.oldpromos.includes(promo)
  },
  isVeryOldPromo(promo) {
    return this.veryOldpromos.includes(promo)
  },
  getEmployerCollection(promo) {
    return this.veryOldpromos.includes(promo) ? 'employers' : 'employerUsers'
  }
}

export const promos = [
  'nerea',
  'mileva',
  'lee',
  'kizzy',
  'jemison',
  'idelisa',
  'hamilton',
  'grace',
  'fairfax',
  'easley'
]
