import React from 'react';
import { SvgIcon } from '@material-ui/core'


const Location = () => (
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path fill="none" stroke="#556080" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M29 28v29"/><circle cx="29" cy="14" r="14" fill="#dd352e"/><circle cx="24" cy="10" r="3" fill="#f76363"/></svg>
  </SvgIcon>
)

export default Location