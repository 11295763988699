import React from 'react';
import { SvgIcon } from '@material-ui/core'


const Email = () => (
  <SvgIcon>
    <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m0 60v392l256-30v-332z" fill="#14cfff"/><path d="m512 60-286 30v332l286 30z" fill="#28abfa"/><path d="m512 437c0-20.032-7.801-38.867-21.967-53.033l-181-181c-14.166-14.166-33-21.967-53.033-21.967l-60 135.5 60 135.5h256z" fill="#14cfff"/><path d="m202.967 202.967-181 181c-14.166 14.166-21.967 33.001-21.967 53.033v15h256v-271c-20.033 0-38.867 7.801-53.033 21.967z" fill="#4fdbff"/><path d="m256 60-60 135.5 60 135.5c20.033 0 38.867-7.801 53.033-21.967l181-181c14.166-14.166 21.967-33.001 21.967-53.033v-15z" fill="#4fdbff"/><path d="m0 60v15c0 20.032 7.801 38.867 21.967 53.033l181 181c14.166 14.166 33 21.967 53.033 21.967v-271z" fill="#8ae7ff"/><path d="m271 120h-15l-10 15 10 15h15z" fill="#3857bc"/><path d="m241 120h15v30h-15z" fill="#3a6fd8"/><path d="m301 120h30v30h-30z" fill="#3857bc"/><path d="m181 120h30v30h-30z" fill="#3a6fd8"/></g></svg>
  </SvgIcon>
)

export default Email