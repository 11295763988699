import React from 'react'
import { useSnackbar } from 'notistack'
import copy from 'clipboard-copy'
import createPersistedState from 'use-persisted-state'

export function useCopy() {
  const [text, setText] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar()

  /* eslint-disable */
  React.useEffect(
    () => {
      if (text) {
        copy(text)
        enqueueSnackbar(`Copiado ${text}`, { variant: 'success' })
      }
    },
    [text]
  )
  /* eslint-enable */

  return setText
}

export const getTheDate = date =>
  typeof date === 'string' ? new Date(date) : date?.toDate()

export function useUserView() {
  const useUserView2 = createPersistedState('userView')
  const [userView, setUserView] = useUserView2(false)

  return [userView, setUserView]
}
