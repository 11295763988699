import React from 'react'
import { AuthCheck } from 'reactfire'
import { useSession, useAuth } from 'fb/auth'
import AuthUserContext from './auth/AuthUserContext'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { SnackbarProvider } from 'notistack'
import { ConfirmProvider } from 'material-ui-confirm'
import { TransitionGroup } from 'react-transition-group'
import { ModalProvider } from 'react-modal-hook'

import Navigation from './nav/Navigation'
import SignInPage from './account/SignIn'
import SignUpPage from './account/SignUp'
import SignUpAdalaber from './account/SignUpAdalaber'
import SignUpEmpresas from './account/SignUpEmpresas'
import PasswordForgetPage from './account/PasswordForget'
import AdminPage from './Admin'
import Adalaber from './adalaber/Adalaber'
import Employer from './employer/Employer'

import * as routes from 'constants/routes'

import './UI/UI.scss'

const Landing = () => {
  const user = useSession()

  if (!user) return null
  if (user.isAdmin) return <AdminPage />
  if (user.isEmployer) return <Employer />
  if (user.isAdalaber) return <Adalaber />
  return null
}

const App = () => {
  const user = useAuth()
  return (
    <AuthUserContext.Provider value={user}>
      <Router>
        <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
          <ConfirmProvider>
            <ModalProvider container={TransitionGroup}>
              <Switch>
                {/* Sign in */}
                <Route exact path={routes.SIGN_UP} component={SignUpPage} />
                <Route
                  exact
                  path={routes.SIGN_UP_ADALABERS}
                  component={SignUpAdalaber}
                />
                <Route
                  exact
                  path={routes.SIGN_UP_EMPRESAS}
                  component={SignUpEmpresas}
                />
                <Route
                  path={routes.PASSWORD_FORGET}
                  component={PasswordForgetPage}
                />

                {/* Landing */}
                <AuthCheck fallback={<SignInPage />}>
                  <Navigation user={user} />

                  <Route path={['/']} component={Landing} />
                  {/* <Route exact={user && !user.isEmployer} path={'/'} component={Landing} /> */}
                  {/* <Route path={`${routes.OFFERS}/:id`} component={OfferDetail}/> */}
                </AuthCheck>
              </Switch>
            </ModalProvider>
          </ConfirmProvider>
        </SnackbarProvider>
      </Router>
    </AuthUserContext.Provider>
  )
}

export default App
