import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

export default function LoadingButton({text, icon, loading, disabled = false, variant, onClick}) {
  return (
    <Button
      type={onClick ? 'button' : 'submit'}
      fullWidth
      variant={variant || 'contained'}
      color="primary"
      size="large"
      disabled={disabled || loading}
      startIcon={icon || null}
      onClick={onClick}
    >
      {!loading ? (
        text
      ) : (
        <CircularProgress color="white" size={20} />
      )}
    </Button>
  )
}
