import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core'
import { getTheDate, useUserView } from '../../../hooks'
import { useSession } from 'fb/auth'
import Markdown from 'components/UI/Markdown'

const workingModes = {
  "REMOTE": "100% remoto",
  "ON_SITE": "100% presencial",
  "SEMI_PRESENTIAL": "Semipresencial",
  "TEMPORARY_REMOTE": "Remoto situación sanitaria",
  "OTHER": "Otro"
}

const SingleOffer = ({opp, showOriginal}) => {
  const user = useSession()
  const [ userView ] = useUserView()
  const isAdaView = userView === 'ada'
  if (!opp) return null
  const values = [
    {
      original: "1. ¿En qué área prefieres que las candidatas destaquen más?",
      detail: "Perfil técnico",
      value: opp.technicalProfile,
    },
    {
      original: "2. ¿Hacia qué área quieres que se proyecten las candidatas?",
      detail: "Área de proyección",
      value: opp.profileProjection,
    },
    {
      original: "3. Indica por favor el nivel mínimo de inglés que las candidatas deben tener",
      detail: "Nivel de inglés mínimo requerido",
      value: opp.englishLevel,
    },
    {
      original: "4. Indica el tipo de contrato",
      detail: "Tipo de contrato",
      value: opp.contractType === 'Otro' ? opp.otherContractType : opp.contractType,
    },
    {
      original: "5. Indica la escala salarial que ofrece la empresa",
      detail: "Escala salarial",
      value: opp.salaryRange,
    },
    {
      original: "6. Indica la modalidad del puesto de trabajo: ",
      detail: "Modalidad del puesto de trabajo",
      value: opp.workingMode === "OTHER" ? opp.workingModeOther : workingModes[opp.workingMode],
    },
    {
      original: "7. Ubicación de puesto de trabajo",
      detail: "Ubicación del puesto de trabajo",
      value: opp.opportunityLocation,
    },
    {
      original: "8. ¿La empresa ofrece la posibilidad de un trabajo con jornada reducida?",
      detail: "¿Existe posibilida de jornada reducida?",
      value: opp.reducedSchedule,
    },
    {
      original: "9 ¿Es requisito indispensable que las candidatas posean un título universitario?",
      detail: "¿Es requisito indispensable que las candidatas posean un título universitario?",
      value: opp.universitaryStudies,
    },
    {
      original: "10. Fecha de incorporación aproximada",
      detail: "Fecha de incorporación aproximada",
      value: getTheDate(opp.incorporationDate).toLocaleDateString('es-ES'),
    },
    {
      original: "11. Resumen del puesto de trabajo",
      detail: "Resumen del puesto de trabajo",
      value: <Markdown>{opp.positionSummary}</Markdown>,
    },
    {
      original: "12. ¿Por qué aplicar a esta oferta?",
      detail: "Por qué aplicar a esta oferta",
      value: <Markdown>{String(opp.motivational)}</Markdown>,
    },
    {
      original: "13. ¿Qué apoyo va a tener la adalaber en la empresa?",
      detail: "Qué apoyo vas a tener en la empresa",
      value: <Markdown>{String(opp.apoyoEnLaEmpresa)}</Markdown>,
    },
    {
      original: "14. Mail de contacto",
      detail: "Mail de contacto",
      value: opp.contactEmail,
      hide: user.isAdalaber
    },
    {
      original: "15. Comentarios",
      detail: "Comentarios",
      value: opp.comments,
    },
  ]

  return (
    <List>
      {
        values.map(val => {
          if ((val.hide && (user.isAdalaber || isAdaView)) || !val.value) return null
          return (
            <ListItem key={val.detail} divider={!showOriginal}>
              <ListItemText primary={showOriginal ? val.original : val.detail} secondary={val.value} />
            </ListItem>
          )
        })
      }
    </List>
  )
}

export default SingleOffer