import React from 'react'

import { db, storage } from 'fb'
import { isPhone } from 'spain-phone'

import {
  Box,
  Typography,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Radio,
  RadioGroup,
  TextField,
  Select,
  MenuItem,
  Button,
  Snackbar,
  SnackbarContent,
  IconButton,
  Chip,
  Tooltip
} from '@material-ui/core'

import green from '@material-ui/core/colors/green'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const titleOptions = [
  'Título universitario o títulos oficialmente reconocidos como equivalentes, de acuerdo con las leyes reguladoras del sistema educativo vigente',
  'Formación profesional de grado medio o superior o títulos oficialmente reconocidos como equivalentes, de acuerdo con las leyes reguladoras del sistema educativo vigente',
  'Certificado de profesionalidad, de acuerdo con lo previsto en la Ley Orgánica 5/2002, de 19 de junio, de las Cualificaciones y de la Formación Profesional, que habiliten para el ejercicio profesional',
  'Ninguno de los anteriores en los últimos 5 años'
]

const reducedTitleOptions = [
  'Título universitario',
  'Formación profesional de grado medio o superior',
  'Certificado de profesionalidad',
  'Ninguno de los anteriores en los últimos 5 años'
]

const SoftSkillOptions = [
  'Tengo una mentalidad abierta. Soy flexible y adaptable',
  'Soy innovadora, creativa e imaginativa',
  'Soy colaboradora y trabajo en equipo',
  'Soy autónoma y tengo capacidad de toma de decisiones',
  'Busco el aprendizaje continuo y la autoformación',
  'Tengo pensamiento crítico. Soy curiosa e inquieta',
  'Poseo habilidades comunicativas',
  'Soy resolutiva y resiliente ante imprevistos',
  'Soy implicada y comprometida',
  'Me oriento hacia los resultados',
  'Me oriento hacia la calidad y los detalles'
]

const Experiencia = [
  'Trato directo con clientes',
  'Gestión de equipos',
  'Priorización de tareas',
  'Planificación de tareas',
  'Realización de presentaciones',
  'Metodologías de trabajo (framework Agile)',
  'Trabajo en remoto',
  'Estimación de tareas y cumplimiento de plazos'
]

const StudyOptions = [
  'Sin estudios obligatorios',
  'ESO',
  'Bachillerato',
  'FP Básica',
  'FP Media',
  'FP Superior',
  'Estudios universitarios',
  'Grado',
  'Máster / Postgrado'
]

const EnglishLevels = [
  'A1.1 - A1.2',
  'A1.2 - A2.1',
  'A2.1 - A2.2',
  'B1.1 - B1.2',
  'B2.1 - B2.2',
  'B2.2 - C1.1',
  'C1.2 - C2.1',
  'C2.1 - C2.2',
  'Nativo'
]

class EditProfile extends React.Component {
  constructor(props) {
    super(props)

    this.myFileField = React.createRef()
    this.myFileFieldEn = React.createRef()

    this.handleFilePicker = this.handleFilePicker.bind(this)
    this.handleFileEnPicker = this.handleFileEnPicker.bind(this)
    this.uploadFile = this.uploadFile.bind(this)

    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeArray = this.handleChangeArray.bind(this)
    this.handleChangeBoolean = this.handleChangeBoolean.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.loadProfile = this.loadProfile.bind(this)

    this.state = {
      open: false
    }
  }

  handleRgpd = () => {
    this.setState({
      ...this.state,
      rgpdConsent: !this.state.rgpdConsent
    })
  }

  handleFilePicker() {
    this.myFileField.current.click()
  }

  handleFileEnPicker() {
    this.myFileFieldEn.current.click()
  }

  uploadFile(e, lang = 'es') {
    const myFile = e.currentTarget.files[0]

    if (lang == 'en') {
      storage
        .uploadPDF(
          `${this.props.authUser.profile.fullName} (ENGLISH) - Adalab.pdf`,
          myFile
        )
        .then(cvEnURL => this.setState({ cvEnURL }))
    } else {
      storage
        .uploadPDF(
          `${this.props.authUser.profile.fullName} - Adalab.pdf`,
          myFile
        )
        .then(cvURL => this.setState({ cvURL }))
    }
  }

  handleAvatarImg = img => {
    this.setState({
      avatar: img
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }

  handleChangeAsArray = event => {
    const { name, value } = event.target
    this.setState({
      [name]: [value]
    })
  }

  handleChangeBoolean(event) {
    const { name } = event.target
    this.setState(prevState => ({
      [name]: !prevState[name]
    }))
  }

  handleChangeArray(event) {
    const { name, value } = event.target

    this.setState(prevState => {
      let list = prevState[name]
      if (!list) list = []
      if (!list.includes(value)) {
        list.push(value)
      } else {
        list = list.filter(el => el !== value)
      }
      return {
        [name]: list
      }
    })
  }

  saveProfile(event) {
    event.preventDefault()
    // eslint-disable-next-line no-unused-vars
    const { open, ...data } = this.state
    data.new = false
    db.updateAdalaber(this.props.authUser.uid, data).then(() => {
      this.setState({ open: true })
      this.loadProfile()
      if (this.props && this.props.close) {
        this.props.close()
      }
    })
  }

  loadProfile() {
    db.getAdalaber(this.props.authUser.uid).then(profile =>
      this.setState({ ...profile })
    )
  }

  componentDidMount() {
    this.loadProfile()
  }

  shouldDisableCheckbox = value => {
    const maxAllowed = 3
    const { checked } = this.state
    return checked.length >= maxAllowed && checked.indexOf(value) === -1
  }

  render() {
    const {
      additionalLocations,
      apprenticeshipContract,
      careerProjection,
      comments,
      // fullName,
      preferredCompanies,
      // problemsToWorkNow,
      shortBio,
      // reducedSchedule,
      // remoteOK,
      cvURL,
      cvEnURL,

      // Nuevas preguntas!
      movilidad,
      otrosIdiomas,
      softskills,
      experiencia,
      github,
      linkedin,
      tel,
      portafolio,
      rgpdConsent,

      // Completar su propio perfil
      studiesDescription,
      studies,
      englishLevel
    } = this.state
    return (
      <>
        <form className="form">
          <FormControl required component="fieldset">
            <FormLabel component="legend">
              1. ¿En qué tipo/s de de empresa/s te gustaría trabajar?
            </FormLabel>
            <TextField
              name="preferredCompanies"
              multiline
              rowsMax="4"
              value={preferredCompanies}
              onChange={this.handleChange}
              margin="normal"
            />
          </FormControl>

          <FormControl required component="fieldset">
            <FormLabel component="legend">
              2. ¿Hacía qué área proyectas tu carrera profesional en el sector
              digital?
            </FormLabel>
            <FormHelperText>
              Elige 1 de las 2 opciones. Esta elección no limita el tipo de
              ofertas que van a llegarte.
            </FormHelperText>
            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    checked={
                      !!careerProjection &&
                      careerProjection.includes(
                        'Front-end con especial interés en maquetación, diseño y UX'
                      )
                    }
                    onChange={this.handleChangeAsArray}
                    value="Front-end con especial interés en maquetación, diseño y UX"
                    inputProps={{ name: 'careerProjection' }}
                  />
                }
                label="Front-end con especial interés en maquetación, diseño y UX"
              />

              <FormControlLabel
                control={
                  <Radio
                    checked={
                      !!careerProjection &&
                      careerProjection.includes(
                        'Front-end con especial interés en Javascript'
                      )
                    }
                    onChange={this.handleChangeAsArray}
                    value="Front-end con especial interés en Javascript"
                    inputProps={{ name: 'careerProjection' }}
                  />
                }
                label="Front-end con especial interés en Javascript"
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">3. Ubicaciones adicionales</FormLabel>
            <TextField
              name="additionalLocations"
              multiline
              rowsMax="4"
              value={additionalLocations}
              onChange={this.handleChange}
              margin="normal"
            />
            <FormHelperText>
              Si estás dispuesta a empezar a trabajar en una ubicación diferente
              a Madrid, indica en cuál o cuáles.
            </FormHelperText>
          </FormControl>

          <FormControl required component="fieldset">
            <FormLabel component="legend">
              4. Disponibilidad geográfica para viajar
            </FormLabel>
            {/* <FormHelperText>
              Elige 1 de las 2 opciones. Esta elección no limita el tipo de
              ofertas que van a llegarte.
            </FormHelperText> */}
            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    checked={!!movilidad && movilidad.includes('Sí')}
                    onChange={this.handleChangeAsArray}
                    value="Sí"
                    inputProps={{ name: 'movilidad' }}
                  />
                }
                label="Sí"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={!!movilidad && movilidad.includes('No')}
                    onChange={this.handleChangeAsArray}
                    value="No"
                    inputProps={{ name: 'movilidad' }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>

          <FormControl required component="fieldset">
            <Tooltip
              title={
                <>
                  <Typography color="inherit">
                    Descripción de las opciones:
                  </Typography>
                  <ol className="help-text">
                    {titleOptions.map(option => {
                      return (
                        <li key={option}>
                          {option}
                          <br />
                        </li>
                      )
                    })}
                  </ol>
                </>
              }
            >
              <FormLabel component="legend">
                5. Selecciona si has obtenido o no alguno de estos títulos en
                los últimos 5 años
              </FormLabel>
            </Tooltip>
            <Select
              value={apprenticeshipContract || ''}
              onChange={this.handleChange}
              inputProps={{ name: 'apprenticeshipContract' }}
            >
              {reducedTitleOptions.map(option => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>
            <FormControl required component="fieldset">
              <FormLabel component="legend">6. Nivel de estudios</FormLabel>
              <Select
                value={studies || ''}
                onChange={this.handleChange}
                inputProps={{ name: 'studies' }}
              >
                {StudyOptions.map(option => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">7. Descripción de estudios</FormLabel>
            <TextField
              name="studiesDescription"
              multiline
              rowsMax="4"
              value={studiesDescription}
              onChange={this.handleChange}
              margin="normal"
            />
          </FormControl>

          <FormControl required component="fieldset">
            <FormLabel component="legend">
              8. Marca las <em>softskills</em> en las que destacas
            </FormLabel>
            <FormHelperText>
              Puedes elegir un máximo de 3 opciones
            </FormHelperText>
            <FormGroup>
              {SoftSkillOptions.map(opt => (
                <FormControlLabel
                  key={opt}
                  control={
                    <Checkbox
                      checked={!!softskills && softskills.includes(opt)}
                      disabled={
                        softskills &&
                        !softskills.includes(opt) &&
                        softskills.length >= 3
                      }
                      onChange={this.handleChangeArray}
                      value={opt}
                      inputProps={{ name: 'softskills' }}
                    />
                  }
                  label={opt}
                />
              ))}
            </FormGroup>
          </FormControl>

          <FormControl required component="fieldset">
            <FormLabel component="legend">
              9. Indica si tienes experiencia en algunas de estas tareas
            </FormLabel>
            <FormHelperText>
              Puedes elegir todas las opciones que quieras
            </FormHelperText>
            <FormGroup>
              {Experiencia.map(opt => (
                <FormControlLabel
                  key={opt}
                  control={
                    <Checkbox
                      checked={!!experiencia && experiencia.includes(opt)}
                      onChange={this.handleChangeArray}
                      value={opt}
                      inputProps={{ name: 'experiencia' }}
                    />
                  }
                  label={opt}
                />
              ))}
            </FormGroup>
          </FormControl>

          <Box>
            <FormControl required component="fieldset">
              <FormLabel component="legend">10. Nivel de Inglés</FormLabel>
              <Select
                value={englishLevel || ''}
                onChange={this.handleChange}
                inputProps={{ name: 'englishLevel' }}
              >
                {EnglishLevels.map(option => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              11. Nivel de otros idiomas distintos al inglés
            </FormLabel>
            <FormHelperText>Indica tu nivel en otros idiomas</FormHelperText>
            <TextField
              name="otrosIdiomas"
              multiline
              rowsMax="4"
              value={otrosIdiomas}
              onChange={this.handleChange}
              margin="normal"
            />
          </FormControl>

          <FormControl required component="fieldset" fullWidth>
            <FormLabel component="legend">12. Mi perfil de Github</FormLabel>
            <TextField
              name="github"
              multiline
              rowsMax="4"
              value={github}
              onChange={this.handleChange}
              margin="normal"
            />
          </FormControl>

          <FormControl required component="fieldset" fullWidth>
            <FormLabel component="legend">13. Mi perfil de LinkedIn</FormLabel>
            <TextField
              name="linkedin"
              multiline
              rowsMax="4"
              value={linkedin}
              onChange={this.handleChange}
              margin="normal"
            />
          </FormControl>

          <FormControl required component="fieldset" fullWidth>
            <FormLabel component="legend">14. Mi teléfono</FormLabel>
            <TextField
              name="tel"
              multiline
              rowsMax="4"
              value={tel}
              error={!isPhone(this.state.tel)}
              onChange={this.handleChange}
              margin="normal"
            />
          </FormControl>

          <FormControl required component="fieldset" fullWidth>
            <FormLabel component="legend">
              15. Breve relato profesional
            </FormLabel>
            <TextField
              id="shortBio"
              multiline
              rowsMax="12"
              value={shortBio}
              onChange={this.handleChange}
              margin="normal"
              name="shortBio"
            />
            <FormHelperText>
              Escribe brevemente tu relato profesional, que compartiremos con
              las empresas colaboradoras.
            </FormHelperText>
          </FormControl>

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">16. Otros conocimientos</FormLabel>
            <TextField
              id="additionalComments"
              multiline
              rowsMax="4"
              name="comments"
              value={comments}
              onChange={this.handleChange}
              margin="normal"
            />
            <FormHelperText>
              Herramientas que manejes, hobbies y/o cursos que hayas hecho y
              puedan ser interesantes para un puesto de programadora front end.
              Ejemplos: ilustración de relatos, curso de diseño, curso de
              python,etc.
            </FormHelperText>
          </FormControl>

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              17. Página web o portafolio
            </FormLabel>
            <TextField
              id="portafolio"
              multiline
              rowsMax="4"
              name="portafolio"
              value={portafolio}
              onChange={this.handleChange}
              margin="normal"
            />
            <FormHelperText>
              ¿Tienes un sitio web que enseñe más de tu trabajo?
            </FormHelperText>
          </FormControl>

          <FormControl required component="fieldset" fullWidth>
            <FormLabel component="legend">
              18. Sube tu CV en PDF en español
            </FormLabel>
            {cvURL ? (
              <div>
                <Chip
                  label={
                    <a href={cvURL} target="_blank" rel="noopener noreferrer">
                      Mi CV en PDF en español
                    </a>
                  }
                  onDelete={() => this.setState({ cvURL: null })}
                />
              </div>
            ) : (
              <>
                <br />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleFilePicker}
                >
                  Subir PDF
                </Button>
                <input
                  type="file"
                  accept=".pdf"
                  ref={this.myFileField}
                  onChange={this.uploadFile}
                  style={{ display: 'none' }}
                />
              </>
            )}
          </FormControl>

          <FormControl required component="fieldset" fullWidth>
            <FormLabel component="legend">
              19. Sube tu CV en PDF en inglés
            </FormLabel>
            {cvEnURL ? (
              <div>
                <Chip
                  label={
                    <a href={cvEnURL} target="_blank" rel="noopener noreferrer">
                      Mi CV en PDF en inglés
                    </a>
                  }
                  onDelete={() => this.setState({ cvEnURL: null })}
                />
              </div>
            ) : (
              <>
                <br />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleFileEnPicker}
                >
                  Subir PDF EN
                </Button>
                <input
                  type="file"
                  accept=".pdf"
                  ref={this.myFileFieldEn}
                  onChange={e => this.uploadFile(e, 'en')}
                  style={{ display: 'none' }}
                />
              </>
            )}
          </FormControl>

          <Box mb={4}>
            <FormLabel component="legend" gutterBottom>
              20. Tratamiento de datos
            </FormLabel>
            <br />
            <Typography variant="small" gutterBottom>
              Los datos personales que nos facilites serán tratados por
              ASOCIACIÓN ADALAB como responsable de tratamiento con la única
              finalidad de gestionar tu perfil en nuestra Bolsa de Empleo. Para
              ello tus datos serían facilitados a aquellas empresas a cuyas
              ofertas apliques. Para ejercer tus derechos y más información
              consulta nuestra{' '}
              <a
                target="_blank"
                href="https://adalab.es/assets/PoliticaDePrivacidadAdalab.pdf"
                rel="noopener noreferrer"
              >
                política de privacidad
              </a>
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={!!rgpdConsent}
                  value={!!rgpdConsent}
                  onChange={this.handleRgpd}
                />
              }
              label={
                'Acepto el tratamiento de mis datos con la finalidad indicadas y según la política de privacidad'
              }
            />
          </Box>

          <FormControl component="fieldset" className="block">
            <Button
              size="large"
              onClick={this.saveProfile}
              variant="contained"
              color="primary"
            >
              Guardar perfil
            </Button>
          </FormControl>
        </form>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            message={
              <span className="snack-text">
                <CheckCircleIcon className="left-icon" /> Guardado correctamente
              </span>
            }
            style={{ backgroundColor: green[600] }}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
      </>
    )
  }
}
export default EditProfile
