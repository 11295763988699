import React from 'react'
import config, { promos } from 'config'
import uniqBy from 'lodash.uniqby'

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import * as STATUS from 'constants/status'
// import withAuthorization from '../../auth/withAuthorization';
import {
  usePromo,
  useOpportunities,
  useAdalabers,
  useAllOpportunities
} from 'fb/db'

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Box,
  Fab
} from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import PromoSet from 'components/UI/PromoSet'

import { download } from 'zip/csv'

const getValuesAndPercentage = (value, total) =>
  `${value} / ${total} = ${((value / total) * 100).toFixed(2)}%`

const sum = (a, b) => a + b

// const noDups = (array) => [...new Set(array)]

const Indicators = () => {
  const [promo] = usePromo()
  let [adalabers, activeAdalabers] = useAdalabers(promo)
  let [allAdalabers, allActiveAdalabers] = useAdalabers('all')
  let employers = useAllOpportunities()
  let employersInPromo = useOpportunities(promo)
  if (promo !== 'all') {
    employersInPromo = employersInPromo.filter(emp => emp.promo === promo)
  }

  const insertedInEmployer = adalabers.filter(
    a => a.employer || a.employerPartner || a.employerIsPartner
  ).length
  const insertedInEmployerOutOfNetwork = adalabers.filter(
    a => a.employerOutOfNetwork && !a.employerIsPartner && !a.employerPartner
  ).length

  const totalInserted = insertedInEmployer + insertedInEmployerOutOfNetwork
  const totalAdalabers = adalabers.length
  const totalActiveAdalabers = activeAdalabers.length
  let { lastDayOfPromotion, admittedAdalabers, oldPromoDates } = config
  if (promo === 'all') {
    admittedAdalabers = promos.length * admittedAdalabers
  }

  const adalaberAverage = field =>
    (
      allActiveAdalabers
        .map(a => (a[field] && a[field].length) || 0)
        .reduce(sum, 0) / allActiveAdalabers.length
    ).toFixed(2)

  let adalabersWithIncorporationDate = uniqBy(
    allAdalabers.filter(a => !!a.incorporationDate),
    'fullName'
  )

  const lastDate = promo =>
    config.isOldPromo(promo)
      ? new Date(oldPromoDates[promo].lastDayOfPromotion)
      : new Date(lastDayOfPromotion)

  const averageDaysToIncorporationDate = (
    adalabersWithIncorporationDate
      .map((ada, i) => {
        const diff = differenceInCalendarDays(
          ada.incorporationDate,
          lastDate(ada.promo)
        )
        return diff
      })
      .reduce(sum, 0) / adalabersWithIncorporationDate.length
  ).toFixed(0)

  const totalEmployers = employers.length

  const activeEmployers = employers.filter(e => e.status !== STATUS.DEACTIVATED)

  const totalActiveEmployers = activeEmployers.length

  const employersThatFinishedWithNoHirings = employers.filter(
    e => e.status === STATUS.FINISHED_NO_HIRING
  )

  const employersWithNoHirings = employers.filter(
    e => !e.adalabers || e.adalabers.length < 1
  )

  const employersWithActiveOpportunities = employers.filter(e =>
    STATUS.OPPORTUNITIES_AVAILABLE.includes(e.status)
  )

  const employersThatHiredAdalabers = employersInPromo.filter(
    e => !!e.adalabers && e.adalabers.length > 0
  )

  const averageAdalabersInEmployer = (
    employersThatHiredAdalabers
      .map(e => !!e.adalabers && e.adalabers.length)
      .reduce(sum, 0) / employersThatHiredAdalabers.length
  ).toFixed(2)

  const employerAverage = field =>
    (
      employers
        .map(
          e =>
            allActiveAdalabers.filter(a => a[field] && a[field].includes(e.id))
              .length
        )
        .reduce(sum, 0) / totalActiveEmployers
    ).toFixed(2)

  const getDaysFromCVsentToContacted = (status1, status2) => {
    const filteredEmployers = employers.filter(
      employer =>
        employer.statusHistory &&
        employer.statusHistory.find(s => s.status === status1) &&
        employer.statusHistory.find(s => s.status === status2)
    )
    return (
      filteredEmployers
        .map(employer => {
          employer.statusHistory.sort((a, b) =>
            new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1
          )
          const dateFrom = employer.statusHistory.find(
            s => s.status === status1
          )
          const dateTo = employer.statusHistory.find(s => s.status === status2)
          return differenceInCalendarDays(
            new Date(dateTo.timestamp),
            new Date(dateFrom.timestamp)
          )
        })
        .reduce(sum, 0) / filteredEmployers.length
    ).toFixed(2)
  }

  const indicators = {
    Inserción: {
      'Inserción sobre graduadas': getValuesAndPercentage(
        totalInserted,
        totalAdalabers
      ),
      'Inserción sobre graduadas activas': getValuesAndPercentage(
        totalInserted,
        totalActiveAdalabers
      ),
      'Inserción en colaboradoras': getValuesAndPercentage(
        insertedInEmployer,
        totalInserted
      ),
      'Inserción en externas': getValuesAndPercentage(
        insertedInEmployerOutOfNetwork,
        totalInserted
      ),
      'Inserción sobre admitidas': getValuesAndPercentage(
        totalInserted,
        admittedAdalabers
      ),
      'Media de alumnas contratadas por empresa colaboradora': averageAdalabersInEmployer
    },
    Adalabers: {
      'Media de ofertas a las que se apunta una alumna': adalaberAverage(
        'accepted'
      ),
      'Media de empresas que contactan a una alumna': adalaberAverage(
        'contacted'
      ),
      'Media de días desde que termina la promoción hasta que comienzan a trabajar': averageDaysToIncorporationDate,
      'Media de alumnas interesadas en una oferta': employerAverage('accepted'),
      'Media de días desde envío de CVs hasta que contactan': getDaysFromCVsentToContacted(
        STATUS.PLAZO_CERRADO,
        STATUS.IN_PROCESS
      ),
      'Media de días desde envío de CVs hasta que termina el proceso de selección': getDaysFromCVsentToContacted(
        STATUS.PLAZO_CERRADO,
        STATUS.FINISHED_SUCCESS
      )
    },
    Empresas: {
      Empresas: `${totalActiveEmployers} total / ${
        employersWithActiveOpportunities.length
      } activas`,
      'Empresas contratadoras': getValuesAndPercentage(
        employersThatHiredAdalabers.length,
        totalActiveEmployers
      ),
      'Empresas todavía sin contratar': getValuesAndPercentage(
        employersWithNoHirings.length,
        totalActiveEmployers
      ),
      'Empresas que terminan sin contratar': getValuesAndPercentage(
        employersThatFinishedWithNoHirings.length,
        totalActiveEmployers
      ),
      'Empresas desactivadas': getValuesAndPercentage(
        totalEmployers - totalActiveEmployers,
        totalEmployers
      )
    }
  }

  const categories = Object.keys(indicators)

  return (
    <div className="container">
      <Toolbar>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={download(
              [
                categories.reduce(
                  (acc, cat) => ({ ...acc, ...indicators[cat] }),
                  {}
                )
              ],
              'indicators'
            )}
          >
            <CloudDownload style={{ marginRight: '1em' }} />
            Descargar
          </Fab>
        </Box>
      </Toolbar>
      <Grid container spacing={2} display="flex" alignItems="stretch">
        {categories.map(category => (
          <Grid item xs={4} key={category}>
            <Card style={{ height: '100%' }}>
              {category === 'Inserción' ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <CardHeader title={category} component="h2" />
                  <PromoSet />
                </Box>
              ) : (
                <CardHeader title={category} component="h2" />
              )}
              <CardContent style={{ padding: 0 }}>
                <List>
                  {Object.keys(indicators[category]).map(indicator => (
                    <ListItem divider={true} key={indicator}>
                      <ListItemText
                        primary={indicator}
                        secondary={indicators[category][indicator]}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

// const authCondition = authUser => authUser.isAdmin;

// export default withAuthorization(authCondition)(Indicators);
export default Indicators
