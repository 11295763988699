import React, { useState } from 'react'
import { useFirestore } from 'reactfire'
import omit from 'lodash.omit'

import { useSession } from 'fb/auth'
import config from 'config'
import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'
import { sendOppToAdminSlack } from 'zapier'

import {
  Container,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Checkbox,
  Box
} from '@material-ui/core'

import LoadingButton from 'components/UI/LoadingButton'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const workingModes = {
  "REMOTE": "100% remoto",
  "ON_SITE": "100% presencial",
  "SEMI_PRESENTIAL": "Semipresencial",
  "TEMPORARY_REMOTE": "Remoto situación sanitaria",
  "OTHER": "Otro"
}

const NewOffer = ({ children, hideModal, showSuccessModal }) => {
  const [loading, setLoading] = React.useState(false)
  const user = useSession()
  const firestore = useFirestore()
  const fieldValue = useFirestore.FieldValue
  const newDate = new Date()

  const [offer, setOffer] = useState({
    title: '',
    technicalProfile: '',
    profileProjection: '',
    englishLevel: '',
    contractType: '',
    otherContractType: '',
    salaryRange: '',
    opportunityLocation: '',
    reducedSchedule: '',
    universitaryStudies: '',
    incorporationDate: '',
    apoyoEnLaEmpresa: '',
    positionSummary: '',
    motivational: '',
    contactEmail: '',
    comments: '',
    checkInterest: false,
    requestDate: newDate,
    workingMode: '',
    workingModeOther: '',
    promo: '',
    employer: user.uid,
    status: 'Nueva',
    creationTimestamp: useFirestore.FieldValue.serverTimestamp()
  })
  const { enqueueSnackbar } = useSnackbar()
  const [currentPromo, setCurrentPromo] = useState();

  React.useEffect(() => {
    const docRef = firestore.collection("config").doc("general");

    docRef.get().then(doc => {
      const { currentPromo } = doc.data();

      setCurrentPromo(currentPromo);
      setOffer(prevOffer => ({ ...prevOffer, promo: currentPromo }))
    });
  }, []);

  const handleSubmit = async e => {
    console.log('Creando nueva oferta')
    e.preventDefault()

    try {
      setLoading(true)
      if (!user || !offer) return

      const newOffer = await firestore
        .collection('employerUsers')
        .doc(user.uid)
        .collection('opportunities')
        .add({
          ...offer,
          incorporationDate: offer.incorporationDate.toISOString()
        })

      await firestore
        .collection('employerUsers')
        .doc(user.uid)
        .update({
          opportunityIDs: fieldValue.arrayUnion(newOffer.id),
          currentPromo
        })

      const prod =
        !window.location.href.includes('netlify') &&
        !window.location.href.includes('localhost') &&
        !window.location.href.includes('emma-demo')

      if (prod) {
        await sendOppToAdminSlack({
          oferta: {
            ...offer,
            incorporationDate: offer.incorporationDate.toISOString()
          },
          empresa: user.profile
        })
      }

      showSuccessModal()
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    setLoading(false)
    enqueueSnackbar('Nueva oferta creada', { variant: 'success' })
    hideModal()
  }

  const handleChange = prop => event => {
    setOffer({ ...offer, [prop]: event.target.value })
  }

  const handleDate = date => setOffer({ ...offer, incorporationDate: date })

  return (
    <Container maxWidth="sm">
      <FixedLinearProgress isLoading={loading} />
      <form onSubmit={handleSubmit} style={{ padding: '0 0 1em' }}>
        <TextField
          onChange={handleChange('title')}
          variant="outlined"
          margin="normal"
          fullWidth
          id="title"
          label="0. Título de la oferta"
          helperText="Introduce un título descriptivo."
          name="title"
          value={offer.title}
          required={true}
          style={{ marginBottom: '2em' }}
        />
        <br />
        <FormControl component="fieldset" required>
          <FormLabel component="legend">
            1. ¿En qué área prefieres que las candidatas destaquen más?
          </FormLabel>
          <FormHelperText style={{ marginBottom: '1em' }}>
            Todas nuestras alumnas han pasado con éxito el bootcamp de Adalab
            que incluye maquetación, JS y React, pero nos gustaría entender
            mejor vuestras preferencias según las necesidades de la empresa.
          </FormHelperText>
          <RadioGroup
            aria-label="technicalProfile"
            name="technicalProfile"
            value={offer.technicalProfile}
            onChange={handleChange('technicalProfile')}
          >
            <FormControlLabel
              value="Maquetación"
              control={<Radio required={true}/>}
              label="Maquetación"
            />
            <FormControlLabel
              value="Maquetación + JavaScript"
              control={<Radio required={true}/>}
              label="Maquetación + JavaScript"
            />
            <FormControlLabel
              value="JavaScript"
              control={<Radio required={true}/>}
              label="JavaScript"
            />
            <FormControlLabel value="React" control={<Radio required={true}/>} label="React" />
          </RadioGroup>
        </FormControl>

        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend">
            2. ¿Hacia qué área quieres que se proyecten las candidatas?
          </FormLabel>
          <FormHelperText style={{ marginBottom: '1em' }}>
            Para entender mejor qué áreas van a tener más peso en las funciones
            a desempeñar por las candidatas
          </FormHelperText>
          <RadioGroup
            aria-label="profileProjection"
            name="profileProjection"
            value={offer.profileProjection}
            onChange={handleChange('profileProjection')}
          >
            <FormControlLabel
              value="Maquetación / Diseño / UX"
              control={<Radio required={true}/>}
              label="Maquetación / Diseño / UX"
            />
            <FormControlLabel
              value="Programación"
              control={<Radio required={true}/>}
              label="Programación"
            />
          </RadioGroup>
        </FormControl>

        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '0' }}>
            3. Indica por favor el nivel mínimo de inglés que las candidatas
            deben tener
          </FormLabel>
          <RadioGroup
            aria-label="englishLevel"
            name="englishLevel"
            value={offer.englishLevel}
            onChange={handleChange('englishLevel')}
            style={{ margin: 0 }}
          >
            <FormControlLabel
              value="No es necesario"
              control={<Radio required={true}/>}
              label="No es necesario"
            />
            <FormControlLabel value="B1" control={<Radio required={true}/>} label="B1" />
            <FormControlLabel value="B2" control={<Radio required={true}/>} label="B2" />
            <FormControlLabel value="C1" control={<Radio required={true}/>} label="C1" />
            <FormControlLabel value="C2" control={<Radio required={true}/>} label="C2" />
          </RadioGroup>
        </FormControl>

        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend">
            4. Indica el tipo de contrato
          </FormLabel>
          <FormHelperText style={{ marginBottom: '1em' }}>
            No trabajamos con becas, prácticas ni contratos formativos
          </FormHelperText>
          <RadioGroup
            aria-label="contractType"
            name="contractType"
            value={offer.contractType}
            onChange={handleChange('contractType')}
          >
            <FormControlLabel
              value="Contrato indefinido"
              control={<Radio required={true}/>}
              label="Contrato indefinido"
            />
            <FormControlLabel
              value="Contrato temporal (mínimo 6 meses)"
              control={<Radio required={true}/>}
              label="Contrato temporal (mínimo 6 meses)"
            />
            <FormControlLabel
              value="Contrato de servicios (autónoma)"
              control={<Radio required={true}/>}
              label="Contrato de servicios (autónoma)"
            />
            <FormControlLabel value="Otro" control={<Radio required={true} />} label="Otro" />
          </RadioGroup>
        </FormControl>

        <br />
        {offer.contractType == "Otro" &&
          <TextField
            onChange={handleChange('otherContractType')}
            variant="outlined"
            margin="normal"
            fullWidth
            id="otherContractType"
            label="Otro tipo de contrato"
            name="otherContractType"
            value={offer.otherContractType}
            required
          />
        }

        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend">
            5. Indica la escala salarial que ofrece la empresa
          </FormLabel>
          <RadioGroup
            helperText="Por favor indicar el salario bruto anual. Recuerda que en Adalab no trabajamos con ofertas con un rango salarial a los 16.000 €"
            aria-label="salaryRange"
            name="salaryRange"
            value={offer.salaryRange}
            onChange={handleChange('salaryRange')}
          >
            <FormControlLabel
              value="16.000 - 18.000 €"
              control={<Radio required={true}/>}
              label="16.000 - 18.000 €"
            />
            <FormControlLabel
              value="18.000 - 20.000 €"
              control={<Radio required={true}/>}
              label="18.000 - 20.000 €"
            />
            <FormControlLabel
              value="20.000 - 22.000€"
              control={<Radio required={true}/>}
              label="20.000 - 22.000€"
            />
            <FormControlLabel
              value="22.000 - 24.000 €"
              control={<Radio required={true}/>}
              label="22.000 - 24.000 €"
            />
            <FormControlLabel
              value="más de 24.000 €"
              control={<Radio required={true}/>}
              label="más de 24.000 €"
            />
          </RadioGroup>
        </FormControl>

        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend">
            6. Indica la modalidad del puesto de trabajo:
          </FormLabel>
          <RadioGroup
            aria-label="workingMode"
            name="workingMode"
            value={offer.workingMode}
            onChange={handleChange('workingMode')}
          >
            {Object.keys(workingModes).map(mode => (
              <FormControlLabel
                key={mode}
                value={mode}
                control={<Radio required={true}/>}
                label={workingModes[mode]}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {["ON_SITE", "SEMI_PRESENTIAL"].includes(offer.workingMode) &&
          <TextField
            required
            onChange={handleChange('opportunityLocation')}
            variant="outlined"
            margin="normal"
            fullWidth
            id="opportunityLocation"
            label="Ubicación de puesto de trabajo"
            helperText="Por favor indicar código postal"
            name="opportunityLocation"
            value={offer.opportunityLocation}
          />
        }

        {offer.workingMode == "OTHER" &&
          <TextField
            required
            onChange={handleChange('workingModeOther')}
            variant="outlined"
            margin="normal"
            fullWidth
            id="workingModeOther"
            label="Describe la modalidad y ubicación del puesto de trabajo"
            name="workingModeOther"
            value={offer.workingModeOther}
            required={true}
          />
        }
        
        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '1em' }}>
            7. ¿La empresa ofrece la posibilidad de un trabajo con jornada
            reducida?
          </FormLabel>
          <FormHelperText>
            (30 - 35 horas) y/o la posibilidad de acordar un horario intensivo
            (por ejemplo 7:00-15:00)
          </FormHelperText>
          <RadioGroup
            value={offer.reducedSchedule}
            onChange={handleChange('reducedSchedule')}
          >
            <FormControlLabel value="Sí" control={<Radio required={true}/>} label="Sí" />
            <FormControlLabel value="No" control={<Radio required={true}/>} label="No" />
          </RadioGroup>
        </FormControl>

        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '-1em' }}>
            8. ¿Es requisito indispensable que las candidatas posean un título
            universitario?
          </FormLabel>
          <RadioGroup
            value={offer.universitaryStudies}
            onChange={handleChange('universitaryStudies')}
          >
            <FormControlLabel value="Sí" control={<Radio required={true}/>} label="Sí" />
            <FormControlLabel value="No" control={<Radio required={true}/>} label="No" />
          </RadioGroup>
        </FormControl>

        <FormControl
          component="fieldset"
          required
          style={{ marginTop: '2em', marginBottom: '1em' }}
        >
          <FormLabel
            component="legend"
            style={{ marginBottom: '1em' }}
            htmlFor="incorporationDate"
          >
            9. Fecha de incorporación aproximada
          </FormLabel>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={offer.incorporationDate}
            onChange={date => handleDate(date)}
            id="incorporationDate"
            required
          />
        </FormControl>

        <TextField
          required
          multiline
          onChange={handleChange('positionSummary')}
          variant="outlined"
          margin="normal"
          fullWidth
          id="positionSummary"
          label="10. Resumen del puesto de trabajo"
          helperText="Contar por favor de forma clara y resumida qué hará una adalaber si finalmente es contratada en la empresa: tipo de proyectos, tecnologías que utilizará, cómo será su onboarding, etc."
          name="positionSummary"
          value={offer.positionSummary}
        />

        <TextField
          required
          multiline
          onChange={handleChange('motivational')}
          variant="outlined"
          margin="normal"
          fullWidth
          id="motivational"
          label="11. ¿Por qué aplicar a esta oferta?"
          helperText="Incluir qué ofrece la empresa para un perfil junior en términos de formación, flexibilidad, ambiente, proyección, apoyo..."
          name="motivational"
          value={offer.motivational}
        />

        <TextField
          required
          onChange={handleChange('apoyoEnLaEmpresa')}
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          id="apoyoEnLaEmpresa"
          label="12. ¿Qué apoyo va a tener la adalaber en la empresa?"
          helperText="Como perfil junior que es, incluir aquí si la alumna contará con un tutor/a en la empresa, con formación específica durante su onboarding y/o si la empresa cuenta con al menos un perfil senior de front-end al que la alumna pueda consultar"
          name="apoyoEnLaEmpresa"
          value={offer.apoyoEnLaEmpresa}
        />

        <TextField
          required
          onChange={handleChange('contactEmail')}
          variant="outlined"
          margin="normal"
          fullWidth
          id="contactEmail"
          label="13. Mail de contacto"
          helperText="Indicar por favor el mail de contacto si tenemos alguna duda desde Adalab una vez leido el formulario"
          name="contactEmail"
          value={offer.contactEmail}
          type="email"
        />

        <TextField
          required
          multiline
          onChange={handleChange('comments')}
          variant="outlined"
          margin="normal"
          fullWidth
          id="comments"
          label="14. Comentarios"
          helperText="Incluir aquí cualquier comentario adicional que quieras que las alumnas interesadas en aplicar a la oferta puedan leer. Si el puesto es 100% en remoto o existe la posibilidad de incorporarse 100% en remoto pero luego se exigirá cierta presencialidad, por favor explicar en detalle aquí."
          name="comments"
          value={offer.comments}
          style={{ marginBotton: '1em' }}
        />

        <Box style={{ marginTop: '1em', marginBottom: '1em' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={offer.checkInterest}
                onChange={handleChange('checkInterest')}
                value={offer.checkInterest}
                color="primary"
              />
            }
            label="Entiendo que todas las alumnas de Adalab a cuyos CVs voy a tener acceso, tiene un perfil front end junior y manejan HTML, CSS, JavaScript y React y estoy interesado/a en este perfil"
          />
        </Box>

        <Box mt={2}>
          <LoadingButton
            text="Crear oferta"
            loading={loading}
            disabled={!offer.checkInterest || loading}
          />
        </Box>
      </form>
    </Container>
  )
}

export default NewOffer
