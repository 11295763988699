import React from 'react';
import { SvgIcon } from '@material-ui/core'


const Exp = () => (
  <SvgIcon>
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m24 376h168v112h-168z" fill="#6cbbc7"/><path d="m24 463.99v24.01h168v-112h-10.958a399.336 399.336 0 0 1 -157.042 87.99z" fill="#518c95"/><g fill="#ff6536"><path d="m368 80h-32a24.027 24.027 0 0 1 -24-24v-16a8 8 0 0 1 8-8h48v16h-40v8a8.008 8.008 0 0 0 8 8h32z"/><path d="m416 80h-32v-16h32a8.008 8.008 0 0 0 8-8v-8h-40v-16h48a8 8 0 0 1 8 8v16a24.027 24.027 0 0 1 -24 24z"/><path d="m368 80h16v72h-16z"/></g><path d="m368 136h16a16 16 0 0 1 16 16v16h-48v-16a16 16 0 0 1 16-16z" fill="#fdb62f"/><path d="m224 136v192h32v-96h48v48h32v-48a32 32 0 0 0 -32-32h-16v-64h88v-32h-184v-80h-32v80a32 32 0 0 0 32 32z" fill="#fadbb7"/><rect fill="#fadbb7" height="64" rx="24" width="48" x="232" y="40"/><path d="m352 24v40a24 24 0 0 0 24 24 24 24 0 0 0 24-24v-40z" fill="#fdb62f"/><path d="m144 328h176v160h-176z" fill="#39b54a"/><path d="m144 458.261v29.739h176v-130.447a399.4 399.4 0 0 1 -176 100.708z" fill="#168826"/><path d="m256 280h184v208h-184z" fill="#fdb62f"/><path d="m256 447.232v40.768h184v-208h-9.046a401.692 401.692 0 0 1 -174.954 167.232z" fill="#f29706"/><path d="m368 232h120v256h-120z" fill="#ff421d"/><path d="m368 429.715v58.285h120v-171.172a402.017 402.017 0 0 1 -120 112.887z" fill="#bf3216"/><path d="m304 200h-16v-18.82a401.421 401.421 0 0 1 -64 48.535v98.285h32v-96h48v48h32v-48a32 32 0 0 0 -32-32z" fill="#f4c08e"/><path d="m329.718 136h46.282v-32h-23.413q-10.662 16.58-22.869 32z" fill="#f4c08e"/><path d="m233.605 88.438a23.957 23.957 0 0 0 46.395-8.438v-16a23.832 23.832 0 0 0 -.774-5.758 119.943 119.943 0 0 1 -45.621 30.196z" fill="#f4c08e"/></svg>
  </SvgIcon>
)

export default Exp