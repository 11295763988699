import React from 'react'
import { usePromo, useOpportunities, useAllOpportunities, useAdalabers } from 'fb/db'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Box, Container } from '@material-ui/core'

import * as routes from 'constants/routes'
import EmployerList from './admin/employer/SearchableList'
import NewEmployerDetail from './admin/employer/NewDetail'
import Adalaber from './adalaber/Adalaber'
import AdalaberList from './admin/adalaber/SearchableList'
import Indicators from './admin/indicators/'
import OfferDetail from './employer/OfferDetail'
import OfferDetailAdalaber from './adalaber/OfferDetail'

import withAuthorization from './auth/withAuthorization'

const getEmployer = (employers, id) => employers.find(e => e.id === id) || null;

const Admin = () => {
  const [promo] = usePromo()
  const employers = useOpportunities()
  const allOpportunities = useAllOpportunities()
  const [adalabers, activeAdalabers] = useAdalabers('all')
  const [adasInPromo] = useAdalabers(promo)

  return (
    <Container maxWidth="xl" p={4}>
      <Box py={4}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={routes.INDICATORS} />} />
          <Route exact path={routes.ADMIN} render={() => <Redirect to={routes.INDICATORS} />} />
          <Route exact path={routes.INDICATORS} component={Indicators} />

          <Route exact path={routes.EMPLOYERS} component={() => (
              <EmployerList promo={promo} employers={employers} adalabers={activeAdalabers} />
            )}
          />

          <Route exact path={`${routes.OFFERS}/:id`} render={() => <OfferDetail />} />

          <Route
            exact
            path={`${routes.EMPLOYERS}/:id`}
            render={({ match }) => {
              const employer = getEmployer(employers, match.params.id);
              return (
                employer && (
                  <NewEmployerDetail
                    promo={promo}
                    employers={employers}
                    employer={employer}
                    adalabers={activeAdalabers}
                  />
                )
              );
            }}
          />

          <Route
            exact
            path={routes.ADALABERS}
            render={() => (
              <AdalaberList employers={allOpportunities} adalabers={adasInPromo} />
            )}
          />

          <Route
            exact
            path={`${routes.ADMINOFFERS}/:id`}
            render={({ match }) => {
              const employer = getEmployer(employers, match.params.id);
              return (
                <OfferDetailAdalaber id={employer.id} employer={employer} />
              );
            }}
          />


          <Route
            path={[`${routes.ADALABERS}/:id`, `${routes.OFFERSADALABER}/:opp/:id`]}
            render={({ match }) => {
              const adalaber = adalabers.find(e => e.id === match.params.id) || null;
              return (
                adalaber &&
                employers.length > 0 && (
                  <Adalaber
                    adalaber={adalaber}
                    employers={employers}
                  />
                )
              );
            }}
          />
        </Switch>
    
      </Box>
    </Container>
  )
}

const authCondition = authUser => authUser.isAdmin;

export default withAuthorization(authCondition)(Admin);
// export default Admin
