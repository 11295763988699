import React from 'react';
import { SvgIcon } from '@material-ui/core'


const English = () => (
  <SvgIcon>
    <svg viewBox="0 -41 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M407 0H105C47.102 0 0 47.102 0 105v168.945c0 57.899 47.102 105 105 105h15.582l36.52 46.332a15.001 15.001 0 0023.563 0l36.519-46.332H407c57.898 0 105-47.101 105-105V105C512 47.102 464.898 0 407 0zm0 0" fill="#6787f5"/><path d="M407 0H256v378.945h151c57.898 0 105-47.101 105-105V105C512 47.102 464.898 0 407 0zm0 0" fill="#38478a"/><path d="M199.758 401.055l17.426-22.11zm0 0M512 123.254H385.879l93.883-93.883C460.875 11.195 435.222 0 407 0h-25.145l-59.613 59.613V0h-132.48v59.613L130.148 0H105C76.777 0 51.125 11.195 32.238 29.371l93.883 93.883H0v132.48h126.121l-90.36 90.356-3.503 3.504c18.887 18.168 44.531 29.351 72.742 29.351h15.582l4.234 5.371 63.336-63.34-1.027 57.97h135.113v-59.407l59.211 59.406H407c28.09 0 53.637-11.086 72.496-29.113l-3.531-3.547-90.25-90.55H512zm0 0" fill="#fff"/><path d="M512 123.254H385.879l93.883-93.883C460.875 11.195 435.222 0 407 0h-25.145l-59.613 59.613V0H256v378.945h66.238V319.54l59.211 59.406H407c28.09 0 53.637-11.086 72.496-29.113l-3.531-3.547-90.25-90.55H512zm0 0" fill="#c7d3ed"/><path d="M292.238 153.254V0h-72.476v153.254H0v72.48h219.762l-2.578 153.211h75.054v-153.21H512v-72.481zm0 0" fill="#ff337a"/><path d="M292.238 153.254V0H256v378.945h36.238v-153.21H512v-72.481zm0 0" fill="#d1005b"/><path d="M199.758 133.25v-21.21L88.945 1.226a104.169 104.169 0 00-31.996 10.43L178.547 133.25zm0 0" fill="#ff337a"/><path d="M455.05 11.656a104.169 104.169 0 00-31.995-10.43L312.242 112.04v21.211h21.211zm0 0" fill="#d1005b"/><path d="M56.977 367.305a104.307 104.307 0 0032.003 10.422l110.778-110.782v-21.21h-21.211zm0 0" fill="#ff337a"/><path d="M312.242 245.734v21.274L422.641 377.78a104.279 104.279 0 0032.074-10.316l-121.32-121.73zm0 0" fill="#d1005b"/></svg>
  </SvgIcon>
)

export default English