import { useState, useContext, useEffect } from 'react'
import { useUser } from 'reactfire'
import firebase, { db, auth } from './firebase'
import userContext from '../components/auth/AuthUserContext'

import config from '../config'

export const useAuth = () => {
  const [state, setState] = useState(() => {
    const user = firebase.auth().currentUser
    return user
  })

  const OnChange = async user => {
    if (!user) return

    await checkIfUserIsAdmin(user)
    if (!user.isAdmin) {
      await checkIfUserIsAdalaber(user)
      await checkIfUserIsEmployer(user)
    }

    if (window.location.href.includes('localhost'))
      console.log('🐱‍ Our user: ', user)
    setState(user)
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(OnChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return state
}

const checkIfUserIsAdmin = user => {
  // console.log('Admin check')
  return db
    .collection('admin')
    .get()
    .then(snapshot => snapshot.docs.map(doc => doc.id))
    .then(admin => {
      user.isAdmin = admin.includes(user.uid)
      return user
    })
}

const checkIfUserIsAdalaber = async user => {
  // console.log('Ada check')
  const profile = await firebase
    .firestore()
    .collection('adalabers')
    .doc(user.uid)
    .get()
  if (profile.exists) {
    user.isAdalaber = true
    user.profile = profile.data()
  }

  return user
}

const checkIfUserIsEmployer = async user => {
  // console.log('Employer check')
  const profile = await firebase
    .firestore()
    .collection('employerUsers')
    .doc(user.uid)
    .get()
  if (profile.exists) {
    user.isEmployer = true
    user.profile = profile.data()
  }

  return user
}

export const useSession = () => {
  const user = useContext(userContext)
  return user
}

export const useNewAppUrl = pathName => {
  const user = useContext(userContext)
  const newHostApp =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://localhost:8586'
      : 'https://emma2.adalab.es'
  const [token, setToken] = useState('')

  useEffect(() => {
    const functions = firebase.functions()
    var createCustomToken = functions.httpsCallable('createCustomToken')

    createCustomToken({ userUid: user.uid }).then(result => {
      setToken(result.data.customToken)
    })
  }, [])

  return newHostApp + pathName + `?token=${token}`
}

export const useAuthWithChange = () => {
  const user = useUser()
  const [state, setState] = useState(() => {
    return { initializing: !user, user, error: '' }
  })

  const OnChange = async user => {
    if (user) {
      const adalaberDoc = await firebase
        .firestore()
        .collection('adalabers')
        .doc(user.uid)
        .get()
      if (adalaberDoc.exists) {
        user.isAdalaber = true
        user.profile = adalaberDoc.data()
      } else {
        const employerDoc = await firebase
          .firestore()
          .collection('employerUsers')
          .doc(user.uid)
          .get()
        if (employerDoc.exists) {
          user.isEmployer = true
          user.profile = employerDoc.data()
        } else {
          const adminDoc = await firebase
            .firestore()
            .collection('admin')
            .doc(user.uid)
            .get()
          if (adminDoc.exists) {
            user.isAdmin = true
            user.isAdalaber = false
            user.isEmployer = false
            user.profile = null
          }
        }
      }

      // console.log('🐱‍👓 Our user: ', user);
    }
    setState({ initializing: false, user })
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(OnChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return state
}

export const useSessionWithRole = () => {
  const user = useContext(userContext)
  return [user, user.profile.role]
}

// Sign Up
export const doCreateUserWithEmailAndPassword = async (
  email,
  password,
  fullName
) => {
  try {
    const authUser = await auth.createUserWithEmailAndPassword(email, password)
    const { currentPromo } = (await firebase
      .firestore()
      .collection('config')
      .doc('general')
      .get()).data()

    await db
      .collection('adalabers')
      .doc(authUser.user.uid)
      .set({
        email,
        fullName,
        promo: currentPromo,
        new: true,
        rgpdConsent: true,
        id: authUser.user.uid
      })
  } catch (err) {
    throw new Error(err.message)
  }
}

export const doCreateEmployerWithEmailAndPassword = async (
  email,
  password,
  employer
) => {
  try {
    const authUser = await auth.createUserWithEmailAndPassword(email, password)
    const { currentPromo } = (await firebase
      .firestore()
      .collection('config')
      .doc('general')
      .get()).data()

    const id = authUser.user.uid
    db.collection('employerUsers')
      .doc(id)
      .set({
        ...employer,
        promo: currentPromo,
        uid: id,
        id,
        inscriptionDate: firebase.firestore.FieldValue.serverTimestamp()
      })

    return { message: 'Nueva cuenta creada', variant: 'success' }
  } catch (err) {
    return { message: err.message, variant: 'error' }
  }
}

export const onAuthUserListener = (next, fallback) =>
  auth.onAuthStateChanged(authUser => {
    if (!authUser) return fallback()
    loadIfUserIsAdmin(authUser)
      .then(authUser => next(authUser))
      .catch(() => next(authUser))
  })

const loadIfUserIsAdmin = authUser =>
  db
    .collection('admin')
    .get()
    .then(snapshot => snapshot.docs.map(doc => doc.id))
    .then(admin => {
      authUser.isAdmin = admin.includes(authUser.uid)
      return authUser
    })
    .catch(() => {
      authUser.isAdmin = false
      return authUser
    })

// Password Change
export const doPasswordUpdate = password =>
  auth.currentUser.updatePassword(password)
