import firebase, { db } from './firebase'
import {useEffect} from "react"
import { useFirestore, useFirestoreCollectionData, useUser, useFirestoreDocData } from 'reactfire'
import { useSession } from './auth'
import createPersistedState from 'use-persisted-state'
import config from '../config'
import * as STATUS from 'constants/status'

// Collections
const ADALABERS = 'adalabers'
const EMPLOYERS = 'employers'
const NEWEMPLOYERS = 'employerUsers'
const OPPORTUNITIES = 'opportunities'

const isOldPromo = (promo) => config.oldpromos.includes(promo)

const employerFields = [
  'name',
  'cif',
  'location',
  'website',
  'linkedin',
  'logo',
  'description',
  'type',
  'numberOfEmployees',
]

export function usePromo() {
  // const user = useSession()
  const usePromoState = createPersistedState('promo')
  const [promo, setPromo] = usePromoState()

  useEffect(() => {
    const docRef = db.collection("config").doc("general");

    docRef.get().then(doc => {
      const { currentPromo } = doc.data();
      setPromo(currentPromo);
    });
  }, []);

  return [promo, setPromo]
}

export function useAdalabers(promo) {
  const AdaCollection = useFirestore().collection('adalabers')
  let AllAdalabers = useFirestoreCollectionData(AdaCollection, {
    idField: 'id',
  })
  AllAdalabers = AllAdalabers.sort((a, b) =>
    b.fullName < a.fullName ? 1 : -1
  )

  let adalabers = AllAdalabers.filter(ada => ada.promo)
  adalabers = promo === 'all' ? adalabers : adalabers.filter(ada => ada.promo === promo)
  
  adalabers = adalabers.map(ada => {
    return ({
      ...ada,
      ...(ada.incorporationDate && {
        incorporationDate: !!ada.incorporationDate.toDate
          ? ada.incorporationDate.toDate()
          : ada.incorporationDate
      })
    })
  })

  // Filtering test users
  adalabers = adalabers.filter(ada => !ada.testUser)
    .filter(({email}) => !email.includes('adalab.es'))

  const activeAdalabers = adalabers.filter(a => !a.inactive)



  return [adalabers, activeAdalabers]
}

export function useAdalabersInOffer(id, promo, demo) {
  let [adalabers] = useAdalabers('all')
  let offer = useEmployer(id)

  const collection = offer && offer.accepted ? offer.accepted : offer.adalabers ? offer.adalabers : offer.matches

  return collection
    .map(AdaID => adalabers.find(ada => ada.id === AdaID))
    .sort((a, b) =>
      b.fullName < a.fullName ? 1 : -1
    )  
}


export function useAdalabersWithOffer(id, promo, demo) {
  let [adalabers] = useAdalabers(promo)
  // return adalabers
  return adalabers
    .filter(ada => ada.accepted && ada.accepted.includes(id))
    .filter(ada => !ada.employer && !ada.employerOutOfNetwork)
}


/*

El employer puede ser de cualquier promo, esto está no está del todo bien.

*/
export function useOpportunities(promo) {
  const user = useSession()
  const oldVersion = config.isVeryOldPromo(promo)
  const employerCollection = oldVersion ? 'employers' : 'employerUsers'

  const EmployerCollection = useFirestore().collection(employerCollection)
  const AllEmployers = useFirestoreCollectionData(EmployerCollection, { idField: 'id' })

  const OpportunitiesCollection = useFirestore().collectionGroup('opportunities')
  
  let Opportunities = useFirestoreCollectionData(OpportunitiesCollection, { idField: 'id' })
  Opportunities = Opportunities.map(opp => {
    if (opp.accepted && opp.applicants) opp.applicants = [...opp.applicants, ...opp.accepted]
    return ({
      ...opp,
      promo: opp.currentPromo || opp.promo,
      ...(opp.applicants && { accepted: opp.applicants })
    })
  })

  let employers = AllEmployers
    .map(emp => ({
      ...emp,
      promo: emp.currentPromo ? emp.currentPromo : emp.promo ? emp.promo : 'unknown',
    }))
    .reduce((acc, employer) => {
      if (employer.opportunityIDs) {
        employer.opportunityIDs.map(opp => {
          const opportunity = Opportunities.find(opportunity => opportunity.id === opp)
          // if (!opportunity) return false
          const fullEmployer = {
            ...keepFields(employer, employerFields),
            ...opportunity,
            collection: employerCollection,
            id: opp,
          }
          acc.push(fullEmployer)
          return false
        })
      } else {
        if (user.isAdmin) {
          employer.noOpportunities = true
          employer.status = 'Sin ofertas'
          acc.push(employer)
        }
      }
      return acc
    }, [])


  

  /* let employers = oldVersion
    ? [
        ...new Set(
          Opportunities.map(o => o.id).map(id => Opportunities.find(o => o.id === id))
        )
      ]
      .filter(opp => opp.promo === promo || opp.currentPromo === promo )
      .map(opp => {
        let employer = Employers.find(emp => emp.id === opp.employer)
        
        let fullOpp = {
          ...keepFields(employer, employerFields),
          ...opp,
          collection: employerCollection,
          id: opp.id,
        }
        
        return fullOpp
      })
    : Employers.reduce((acc, employer) => {
      if (employer.opportunityIDs) {
        employer.opportunityIDs.map(opp => {
          const opportunity = Opportunities.find(opportunity => opportunity.id === opp)
          // if (opp.promo !== promo)
  
          // if (employer ) {
          if (
            employer &&
            opportunity &&
            (opportunity.promo || opportunity.currentPromo) &&
            [opportunity.promo, opportunity.currentPromo].includes(promo)
          ) {
            const fullEmployer = {
              ...keepFields(employer, employerFields),
              ...opportunity,
              collection: employerCollection,
              id: opp,
            }
            acc.push(fullEmployer)
          }
          return false
        })
      } else {
        if (user.isAdmin) {
          console.log('IS ADMIN')
          employer.noOpportunities = true
          employer.status = 'Sin ofertas'
          acc.push(employer)
        }
      }
      return acc
    }, []) */
  
  // Filtering & Sorting
  employers = employers.sort((a, b) => new Date(b.requestDate) < new Date(a.requestDate) ? 1 : -1)

  if (user.isAdalaber) {
    // Aquí es donde desaparecen los no válidos
    employers = employers
    .filter(e => e.status !== STATUS.NEW)
    .filter(
      e =>
        (user.profile.accepted && user.profile.accepted.includes(e.id))
        || e.status === STATUS.VALIDATED
    )
    .sort((a, b) => {
      if (a.status === b.status) return b.requestDate - a.requestDate
      return ( STATUS.ALL.indexOf(a.status) - STATUS.ALL.indexOf(b.status) )
    })
  }

  if (user.isEmployer) {
    employers = employers.filter(e => e.status !== STATUS.NEW)
    .sort((a, b) => {
      if (a.status === b.status) return b.requestDate - a.requestDate
      return (
        STATUS.ALL.indexOf(a.status) - STATUS.ALL.indexOf(b.status)
      )
    })
  }

  // if (window.location.href.includes('localhost')) console.log(employers)

  return employers
}

export function useAllOpportunities() {
  let EmployerCollection = useFirestore().collection('employers')
  let AllEmployers = useFirestoreCollectionData(EmployerCollection, { idField: 'id' })


  let EmployerUsersCollection = useFirestore().collection('employerUsers')
  let AllEmployersUsers = useFirestoreCollectionData(EmployerUsersCollection, { idField: 'id' })

  const OpportunitiesCollection = useFirestore().collectionGroup('opportunities')
  
  let Opportunities = useFirestoreCollectionData(OpportunitiesCollection, { idField: 'id' })
  Opportunities = Opportunities.map(opp => {
    if (opp.accepted && opp.applicants) opp.applicants = [...opp.applicants, ...opp.accepted]
    return ({
      ...opp,
      promo: opp.currentPromo || opp.promo,
      ...(opp.applicants && { accepted: opp.applicants })
    })
  })

  let FullEmployerList = [...AllEmployers, ...AllEmployersUsers]

  let employers = FullEmployerList
    .map(emp => ({
      ...emp,
      promo: emp.currentPromo ? emp.currentPromo : emp.promo ? emp.promo : 'unknown',
    }))
    .reduce((acc, employer) => {
      if (employer.opportunityIDs) {
        employer.opportunityIDs.map(opp => {
          const opportunity = Opportunities.find(opportunity => opportunity.id === opp)
          const fullEmployer = {
            ...keepFields(employer, employerFields),
            ...opportunity,
            id: opp,
          }
          acc.push(fullEmployer)
          return false
        })
      } else {
        employer.noOpportunities = true
        employer.status = 'Sin ofertas'
        acc.push(employer)
      }
      return acc
    }, [])  

  employers = employers.sort((a, b) => new Date(b.requestDate) < new Date(a.requestDate) ? 1 : -1)

  return employers
}

export function useEmployer(id) {
  const [promo] = usePromo()
  const employers = useAllOpportunities(promo)
  const employer = employers.find(emp => emp.id === id) || null
  return employer
}

export function useOpps() {
  const user = useUser()
  const OppsCollection = useFirestore().collectionGroup('opportunities').where('employer', '==', user.uid)
  let MyOpps = useFirestoreCollectionData(OppsCollection, { idField: 'id', })
  return MyOpps
}

export function useOpp(id) {
  const OppRef = useFirestore().collectionGroup('opportunities').doc(id)
  let Opp = useFirestoreDocData(OppRef, { idField: 'id', })
  return Opp
}






/* Old Code */
const keepFields = (item, fieldsToKeep) => {
  const reduced = {}
  fieldsToKeep.forEach(field => {
    if (item[field]) reduced[field] = item[field]
  })
  return reduced
}

const collectionOf = entity => db.collection(entity)

const getAll = (entity, promo) =>
  collectionOf(entity)
    .where('promo', '==', promo)
    .get()
    .then(snapshot =>
      snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }))
    )


const getById = entity => id =>
  collectionOf(entity)
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        return ({
          ...doc.data(),
          id: doc.id,
          [entity]: true
        })
      }
      else {
        return ({
          [entity]: false
        })
      }

    })

export const getAllAdalabers = promo => {
  return isOldPromo(promo)
    ? getAll(ADALABERS, promo).then(adalabers => adalabers.map(ada => {
        let date

        if (ada.incorporationDate) {
          date = typeof ada.incorporationDate === 'string' ? new Date(ada.incorporationDate) : ada.incorporationDate.toDate()
        }
        
        return {
          ...ada,
          ...(ada.incorporationDate && { incorporationDate: date })
        }
      }))
    : getAll(ADALABERS, promo).then(adas => adas.map(ada => {
      if (ada.incorporationDate) ada.incorporationDate = ada.incorporationDate.toDate()
      return ada
    }))
}

export const getAllEmployers = promo => {
  const employerCollection = isOldPromo(promo) ? EMPLOYERS : NEWEMPLOYERS
  return getAll(OPPORTUNITIES, promo).then(opps => {
    
    return opps.length > 0
      ? Promise.all(
        opps.map(opp => getById(employerCollection)(opp.employer).then(employer => {
            return ({
              ...keepFields(employer, employerFields),
              ...opp,
              id: opp.id,
            })
          })
        )
      )
      : getAll(employerCollection, promo)
  }
    
  )
}

export const getOnlyEmployers = () => {
  return collectionOf(EMPLOYERS)
    .get()
    .then(snapshot =>
      snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }))
    )
}

const update = entity => (id, newFields) =>
  collectionOf(entity)
    .doc(id)
    .set({ ...newFields }, { merge: true })

/* eslint-disable */
const deleteField = entity => (id, field) => collectionOf(entity).doc(id).update({ [field]: useFirestore.FieldValue.delete() })
/* eslint-enable */
export const updateAdalaber = update(ADALABERS)
// export const updateEmployer = update(OPPORTUNITIES)
export const updateOpportunity = update(OPPORTUNITIES)

export const updateEmployer = (employer, opportunity, obj) =>
  collectionOf('employerUsers').doc(employer).collection('opportunities').doc(opportunity).update(obj)


export const getAdalaber = getById(ADALABERS)
export const getEmployer = getById(EMPLOYERS)
export const getEmployerUser = getById(NEWEMPLOYERS)
export const getOpportunity = getById(OPPORTUNITIES)

export const updateAdalaberActions = async (field, isDone, adalaber, employerId) => {
  return updateAdalaber(adalaber.id, (isDone ? _remove : _add)(field, adalaber[field], employerId))
}

export const acceptAdalaber = async (adalaberID, employerID) => {
  try {
    await db.collection('adalabers').doc(adalaberID).update(
      {'accepted': firebase.firestore.FieldValue.arrayUnion(employerID)}
    )
    return 'OK'
  } catch(err) {
    return err
  }
}

export const unAcceptAdalaber = async (adalaberID, employerID) => {
  try {
    await db.collection('adalabers').doc(adalaberID).update(
      {'accepted': firebase.firestore.FieldValue.arrayRemove(employerID)}
    )
    return 'OK'
  } catch(err) {
    return err
  }
}

const _add = (field, list, id) => {
  const prev = list ? list : []
  return {
    [field]: [...prev, id],
  }
}

const _remove = (field, list, id) => {
  const prev = list ? list : []
  return {
    [field]: prev.filter(e => e !== id),
  }
}
/* eslint-disable */
const _addMatch = (matches, newMatch) => {
  const prevMatches = matches ? matches : []
  return {
    matches: [...prevMatches, newMatch],
  }
}
/* eslint-enable */

/* eslint-disable */
const _addReject = (rejects, newReject) => {
  const prevRejects = rejects ? rejects : []
  return {
    rejects: [...prevRejects, newReject],
  }
}
/* eslint-enable */

const _removeFields = (fields, object, toRemove) =>
  fields.reduce(
    (acc, field) => ({ ...acc, ..._removeField(field, object, toRemove) }),
    {}
  )

const _removeField = (field, object, toRemove) => {
  const prev = object[field] || []
  const index = prev.indexOf(toRemove)
  if (index !== -1) prev.splice(index, 1)
  return {
    [field]: prev,
  }
}

/* eslint-disable */
const _removeMatch = (matches, oldMatch) => {
  const prevMatches = matches ? matches : []
  const index = prevMatches.indexOf(oldMatch)
  if (index !== -1) prevMatches.splice(index, 1)
  return {
    matches: prevMatches,
  }
}
/* eslint-enable */

/* eslint-disable */
const _removeReject = (rejects, oldReject) => {
  const prevRejects = rejects ? rejects : []
  const index = prevRejects.indexOf(oldReject)
  if (index !== -1) prevRejects.splice(index, 1)
  return {
    rejects: prevRejects,
  }
}
/* eslint-enable */

const _addAdalaber = (adalabers, newAdalaber) => {
  const prevAdalabers = adalabers ? adalabers : []
  return {
    adalabers: [...prevAdalabers, newAdalaber],
  }
}

const _removeAdalaber = (adalabers, oldAdalaber) => {
  const prevAdalabers = adalabers ? adalabers : []
  const index = prevAdalabers.indexOf(oldAdalaber)
  if (index !== -1) prevAdalabers.splice(index, 1)
  return {
    adalabers: prevAdalabers,
  }
}

export const insertAdalaberInEmployer = (employer, adalaber) =>
  {
    if (!employer || !adalaber) return 'OK'
    return Promise.all([
      updateAdalaber(adalaber.id, { employer: employer.id }),
      // deleteField(adalaber.id, 'currentPromo'),
      updateEmployer(employer.employer, employer.id, _addAdalaber(employer.adalabers, adalaber.id)),
    ])
  }

export const removeAdalaberFromEmployer = (employer, adalaber) => {
  console.log('REMOVE', adalaber, employer)
  const emptyEmployer = {
    employer: null,
    incorporationDate: null,
    employerOutOfNetwork: null,
    employerName: null
  }
  if (!employer) return updateAdalaber(adalaber.id, emptyEmployer)
  else return Promise.all([
    updateAdalaber(adalaber.id, emptyEmployer),
    updateEmployer(
      employer.employer,
      employer.id,
      _removeAdalaber(employer.adalabers, adalaber.id)
    ),
  ])
}

export const removeEmployerDeactivated = (adalabers, employer) => {
  const adalabersToUpdate = adalabers.filter(
    a => a.accepted && a.accepted.includes(employer.id)
  )
  return Promise.all(
    adalabersToUpdate.map(adalaber =>
      updateAdalaber(
        adalaber.id,
        _removeFields(
          ['accepted', 'matches', 'contacted'],
          adalaber,
          employer.id
        )
      )
    )
  )
}

export const deactivateAdalaber = (adalaber, reason = "") =>
  updateAdalaber(adalaber.id, { inactive: true, deactivateReason: reason })

export const reactivateAdalaber = adalaber =>
  updateAdalaber(adalaber.id, { inactive: false })


export const createOpportunity = async (opportunity) => {
  await fetch('https://hooks.zapier.com/hooks/catch/2965889/ohdasp2/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(opportunity)
  })
}

// export const acceptSendCV = async (Adalaber, OppID) => {
//   return db
//     .updateOpportunity(OppID)
// }