import React from 'react'
import { useSession } from 'fb/auth'
import { Link } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'
import { useModal } from 'react-modal-hook'
import * as routes from 'constants/routes'
import * as STATUS from 'constants/status'
import moment from 'moment'
import 'moment/locale/es'

import { useConfirm } from 'material-ui-confirm'
import { getTheDate } from '../../hooks'
import NuevaOfertaCard from './dashboard/NuevaOfertaCard'
import SingleOfferForm from './SingleOfferForm'

import { Paper, Box, Typography, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent, Tooltip, Button } from '@material-ui/core'
import { Edit, Delete, Close } from '@material-ui/icons'
import SmilingGirl from 'components/UI/CustomIcons/SmilingGirl'

const Offer = ({opp, onDelete, logo}) => {
  // const isFinished = STATUS.FINISHED.includes(opp.status)
  const isNueva = STATUS.NEW.includes(opp.status)
  const plazo = opp.acceptanceDeadline ? Date.parse(getTheDate(opp.acceptanceDeadline)) - Date.parse(new Date()) < 0 : false
  const canNavigate = isNueva || !plazo

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Dialog
        maxWidth="sm"
        open={open}
        onExited={onExited}
        onClose={hideModal}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography component="h2" variant="h3">Editar oferta</Typography>
            
            <IconButton aria-label="close" onClick={hideModal}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <SingleOfferForm opp={opp} />
        </DialogContent>
      </Dialog>
    ),
    [opp]
  )

  if (!opp) return null

  return (
    <TableRow
      key={opp.title}
      style={
      {
        ...(opp.status === STATUS.VALIDATED && {background: 'var(--adaGradientSuperUltraLight)'})
      }
    }>
      <TableCell component="th" scope="row">
      
        <img style={{ width: 110 }} src={logo} alt=""/>
      
      </TableCell>
      
      <TableCell component="th" scope="row">
        {opp.title}
      </TableCell>
      <TableCell>{opp.status}</TableCell>
      <TableCell>
          {!!opp.requestDate && (
            <span>{moment(getTheDate(opp.requestDate)).locale('es').format('LL')}</span>
          )}
      </TableCell>
      <TableCell>
        {
          !!opp.acceptanceDeadline && (
            <Box>
              <Typography variant="h4" style={{ fontSize: '1.4em' }}>
                {moment(getTheDate(opp.acceptanceDeadline)).format('HH:mm')}
              </Typography>
              <Typography variant="body2">{moment(getTheDate(opp.acceptanceDeadline)).locale('es').format('LL')}</Typography>
            </Box>
          )
        }
      </TableCell>
      
      <TableCell align="right">

        {
          isNueva ? (
            <>
              <Tooltip title={isNueva ? 'Editar oferta' : 'La oferta ya está validada. Si necesitas hacer algún cambio ponte en contacto con nosotros'}>
                <span>
                  <IconButton disabled={!isNueva} onClick={() => showModal()}  aria-label="edit" color="primary">
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={isNueva ? 'Eliminar oferta' : 'La oferta ya está validada. Si necesitas hacer algún cambio ponte en contacto con nosotros'}>
                <span>
                  <IconButton disabled={!isNueva} onClick={() => onDelete(opp)} aria-label="delete" color="error">
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          ) : (
            <Tooltip title={canNavigate ? 'Podrás ver las candidatas una vez pasada la fecha de vencimiento' : ''}>
              <span>
                <Button
                  disabled={canNavigate}
                  variant="outlined"
                  color="primary"
                  component={Link} to={`${routes.OFFERS}/${opp.id}`}
                  aria-label="Navegar a oferta" 
                  endIcon={<SmilingGirl />}
                >
                  Ver candidatas
                </Button>
              </span>
            </Tooltip>
          )
        }
      </TableCell>
    
    </TableRow>
  )  
}

const Offers = ({opps}) => {
  const user = useSession()
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const firestore = useFirestore()
  
  if (!opps) return null
  const onDelete = async ({id, employer, title}) => {
    await confirm({
      title: `Seguro que quieres borrar la oferta con título ${title}?`,
      dialogProps: { maxWidth: 'xs' }
    })

    setLoading(true)
    try {
      await firestore.collection('employerUsers').doc(employer).collection('opportunities').doc(id).delete()
      enqueueSnackbar(`Borrada oferta con id ${id} y título ${title}`, { variant: 'success' })
    } catch(err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Paper style={{ width: '100%', height: '100%' }}>
        <FixedLinearProgress loading={loading} />
        <Toolbar>
          <Typography variant="h4">
            Ofertas publicadas
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Título</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Fecha de publicación</TableCell>
                <TableCell>Fecha de vencimiento</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                opps && opps.map(opp => <Offer key={opp.id} opp={opp} logo={user && user.profile && user.profile.logo} onDelete={onDelete} />)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <NuevaOfertaCard position="right" fab />
    </>
  )
}

export default Offers