import React from 'react'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { db } from 'fb'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  Box,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogActions
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useAllOpportunities } from 'fb/db'
import Config from 'config'
import { capitalize } from 'config/utils'
import { getTheDate } from 'hooks'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'
import { insertionNotification } from 'slack'
import moment from 'moment'
var Airtable = require('airtable')

const contractTypes = [
  'indefinido',
  'temporal',
  'obra y servicio',
  'autónoma',
  'otro'
]
const workingModes = {
  REMOTE: '100% remoto',
  ON_SITE: '100% presencial',
  SEMI_PRESENTIAL: 'Semipresencial',
  TEMPORARY_REMOTE: 'Remoto situación sanitaria',
  OTHER: 'Otro'
}

const getDateDiff = (date1, date2) => differenceInCalendarDays(date1, date2)

const EditInsertion = ({ uid, hideModal }) => {
  const profileRef = useFirestore()
    .collection('adalabers')
    .doc(uid)
  const profile = useFirestoreDocData(profileRef)
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [insertion, setInsertion] = React.useState({
    employer: profile.employer || '',
    employerOutOfNetwork: profile.employerOutOfNetwork || '',
    employerIsPartner:
      profile.employerIsPartner === undefined
        ? true
        : profile.employerIsPartner,
    salary: profile.salary || 0,
    contractType: profile.contractType || '',
    otherContractType: profile.otherContractType || '',
    contractDuration: profile.contractDuration || '',
    workingDay: profile.workingDay || '',
    workingDayOther: profile.workingDayOther || '',
    incorporationDate: profile.incorporationDate
      ? getTheDate(profile.incorporationDate)
      : '',
    insertionComments: profile.insertionComments || '',
    workingMode: profile.workingMode || '',
    workingModeOther: profile.workingModeOther || '',
    companySize: profile.companySize || '',
    companyType: profile.companyType || ''
  })

  const employers = useAllOpportunities()
  const profileAccepted = profile.accepted || []
  const getEmployerList = (
    employers &&
    profileAccepted.map(acc => employers.find(emp => emp.id === acc))
  ).filter(n => n)
  const getEmployer = id => (id ? employers.find(emp => emp.id === id) : '')

  const requiredFields = [
    'companySize',
    'companyType',
    'contractType',
    'incorporationDate',
    'salary',
    'workingDay',
    'workingMode'
  ]

  const [errors, setErrors] = React.useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    let values = {
      ...(insertion.employer &&
        insertion.employer !== 'other' && { employer: insertion.employer }),
      ...(insertion.employerOutOfNetwork && {
        employerOutOfNetwork: insertion.employerOutOfNetwork
      }),
      ...(insertion.salary && { salary: insertion.salary }),
      ...(insertion.contractType && { contractType: insertion.contractType }),
      ...(insertion.otherContractType && {
        otherContractType: insertion.otherContractType
      }),
      ...(insertion.contractDuration && {
        contractDuration: insertion.contractDuration
      }),
      ...(insertion.workingDay && { workingDay: insertion.workingDay }),
      ...(insertion.workingDayOther && {
        workingDayOther: insertion.workingDayOther
      }),
      ...(insertion.incorporationDate && {
        incorporationDate: new Date(insertion.incorporationDate)
      }),
      ...(insertion.insertionComments && {
        insertionComments: insertion.insertionComments
      }),
      ...(insertion.companySize && {
        companySize: insertion.companySize
      }),
      ...(insertion.companyType && {
        companyType: insertion.companyType
      }),
      ...(insertion.workingMode && {
        workingMode: insertion.workingMode
      }),
      ...(insertion.workingModeOther && {
        workingModeOther: insertion.workingModeOther
      }),
      ...(insertion.employerIsPartner && {
        employerIsPartner: insertion.employerIsPartner
      })
    }

    if (
      !requiredFields.every(required => Object.keys(values).includes(required))
    ) {
      setErrors(true)
      return
    }

    setErrors(false)
    setLoading(true)

    try {
      if (insertion.employer && !insertion.employerOutOfNetwork) {
        const employer = getEmployer(insertion.employer)
        await db.insertAdalaberInEmployer(employer, profile)
      }

      await profileRef.update({
        ...values
      })

      enqueueSnackbar('Guardado con éxito', { variant: 'success' })
      hideModal()
      insertionNotification({
        ...values,
        student: profile?.fullName,
        employer: getEmployer(values.employer)?.name
      })

      var base = new Airtable({ apiKey: 'keyK4jKnuoo8X9ifV' }).base(
        'appXWRUBK9ZM01XJK'
      )

      const companyName = !values.employerOutOfNetwork
        ? getEmployer(values.employer)?.name
        : values.employerOutOfNetwork
      const weekHours = !values.workingDayOther
        ? 'Completa (40h)'
        : `Parcial (${values.workingDayOther})`
      const duration = !values.contractDuration
        ? 'No definido'
        : values.contractDuration
      const mode =
        values.workingMode !== 'OTHER'
          ? workingModes[values.workingMode]
          : values.workingModeOther
      const isPartner = !values.employerIsPartner ? 'no' : 'sí'
      const insertionDaysDelay =
        values.incorporationDate && Config.oldPromoDates[profile.promo]
          ? getDateDiff(
              values.incorporationDate,
              new Date(Config.oldPromoDates[profile.promo].lastDayOfPromotion)
            )
          : 50000

      base('Adalabers')
        .select({
          filterByFormula: `{Email} = '${profile.email}'`
        })
        .eachPage(function page(records, fetchNextPage) {
          records.forEach(function(record) {
            base('Adalabers').update(
              [
                {
                  id: record.id,
                  fields: {
                    INSERTADAS: 'si',
                    'Nombre de la empresa': companyName,
                    'Tipo de contrato':
                      values.contractType[0].toUpperCase() +
                      values.contractType.substring(1),
                    'Duración del contrato': duration,
                    'Duración de la jornada': weekHours,
                    'Modalidad de trabajo': mode,
                    'Salario bruto anual':
                      values.salary === '0' ? '' : values.salary,
                    'Fecha de incorporación': moment(
                      values.incorporationDate
                    ).format('YYYY-MM-DD'),
                    Comentarios: values.insertionComments,
                    'Días hasta la incorporación': insertionDaysDelay,
                    'Empresa colaboradora Adalab': isPartner,
                    'Tamaño empresa': values.companySize || 'n/d',
                    'Tipología de empresa': values.companyType || '',
                    'Tipología de empresa (otro)': values.otherContractType
                  }
                }
              ],
              function(err, records) {
                if (err) {
                  console.log(err)
                  console.error(err)
                  return
                }
              }
            )
          })
          fetchNextPage()
        })
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    setLoading(false)
  }

  const handleChange = prop => event => {
    let value = event.target.value

    if (prop === 'employerIsPartner') {
      value = value === 'true'
    }

    if (prop === 'salary') {
      value = value.replace(/\./g, '')
    }

    setInsertion({ ...insertion, [prop]: value })
  }

  const handleDate = date =>
    setInsertion({ ...insertion, incorporationDate: date })

  if (!profile) return null
  return (
    <form onSubmit={handleSubmit}>
      <FixedLinearProgress isLoading={loading} />
      <Container maxWidth="sm">
        <Box mb={4}>
          <Typography variant="subtitle1">Detalles de inserción</Typography>
          <Typography variant="body2">
            Toda la información introducida aquí es de carácter confidencial y
            Adalab no la hará pública en ningún momento, solo se utiliza para
            calcular ratios y dar información actualizada en forma de
            porcentajes a las nuevas promociones.
          </Typography>
          {
            <Box mt="4">
              <FormControl fullWidth>
                <InputLabel id="nombre-empresa">
                  Nombre de la empresa
                </InputLabel>
                <Select
                  labelId="nombre-empresa"
                  id="nombre-empresa"
                  value={
                    insertion.employerOutOfNetwork !== ''
                      ? 'other'
                      : insertion.employer
                  }
                  fullWidth
                  onChange={e => {
                    if (e.target.value === 'other') {
                      setInsertion({
                        ...insertion,
                        employer: 'other'
                      })
                      return
                    }
                    const employer = getEmployer(e.target.value)
                    setInsertion({
                      ...insertion,
                      employerOutOfNetwork: '',
                      employer:
                        e.target.value === 'other' ? 'other' : employer.id
                    })
                  }}
                >
                  {getEmployerList.map(emp => (
                    <MenuItem value={emp.id}>{emp.name}</MenuItem>
                  ))}
                  <MenuItem value="other">Empresa externa</MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
          {(insertion.employer === 'other' ||
            insertion.employerOutOfNetwork !== '') && (
            <>
              <TextField
                onChange={handleChange('employerOutOfNetwork')}
                margin="normal"
                required
                fullWidth
                id="employerOutOfNetwork"
                label="Nombre de la empresa"
                name="employerOutOfNetwork"
                value={insertion.employerOutOfNetwork || ''}
              />

              <FormControl required component="fieldset">
                <RadioGroup
                  aria-label="employerIsPartner"
                  name="employerIsPartner"
                  value={insertion.employerIsPartner}
                  onChange={handleChange('employerIsPartner')}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Contratación facilitada por Adalab"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Inserción por mi cuenta"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}

          <FormControl fullWidth style={{ marginTop: 20 }} required>
            <InputLabel id="companyType">Tipología de empresa</InputLabel>
            <Select
              required
              labelId="companyType"
              id="companyType"
              value={insertion.companyType}
              onChange={event => {
                setInsertion(prevState => ({
                  ...prevState,
                  companyType: ''
                }))
                handleChange('companyType')(event)
              }}
              fullWidth
            >
              <MenuItem value="Empresa de producto">
                Empresa de producto
              </MenuItem>
              <MenuItem value="Empresa de servicios">
                Empresa de servicios
              </MenuItem>
              <MenuItem value="Agencia de Marketing/Publicidad">
                Agencia de Marketing o Publicidad
              </MenuItem>
              <MenuItem value="Otro">Otro</MenuItem>
            </Select>
          </FormControl>

          {insertion.companyType === 'Otro' && (
            <TextField
              onChange={handleChange('otherCompanyType')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="otherCompanyType"
              label="¿Qué tipología de empresa es?"
              name="otherContractType"
              value={insertion.otherContractType || ''}
            />
          )}

          <FormControl fullWidth style={{ marginTop: 20 }} required>
            <InputLabel id="companySize">Tamaño de la empresa</InputLabel>
            <Select
              labelId="companySize"
              id="companySize"
              value={insertion.companySize}
              onChange={event => {
                setInsertion(prevState => ({
                  ...prevState,
                  companySize: ''
                }))
                handleChange('companySize')(event)
              }}
              fullWidth
            >
              <MenuItem value="2-10">De 2 a 10 empleados</MenuItem>
              <MenuItem value="11-50">De 11 a 50 empleados</MenuItem>
              <MenuItem value="51-200">De 51 a 200 empleados</MenuItem>
              <MenuItem value="201-500">De 201 a 500 empleados</MenuItem>
              <MenuItem value="501-1000">De 501 a 1.000 empleados</MenuItem>
              <MenuItem value="1001-5000">De 1.001 a 5.000 empleados</MenuItem>
              <MenuItem value="5001-10000">
                De 5.001 a 10.000 empleados
              </MenuItem>
              <MenuItem value="más de 10000">Más de 10.000 empleados</MenuItem>
            </Select>
          </FormControl>

          <TextField
            type="number"
            onChange={handleChange('salary')}
            margin="normal"
            required
            fullWidth
            id="salary"
            label="Salario bruto anual (€)"
            name="salary"
            value={insertion.salary || ''}
          />

          {
            <FormControl
              fullWidth
              style={{ marginTop: 16, marginBottom: 20 }}
              required
            >
              <InputLabel id="contractType">Tipo de contrato</InputLabel>
              <Select
                labelId="contractType"
                required
                id="contractType"
                value={insertion.contractType || ''}
                onChange={handleChange('contractType')}
                fullWidth
              >
                {contractTypes.map(c => (
                  <MenuItem value={c}>{capitalize(c)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }

          {insertion.contractType === 'otro' && (
            <TextField
              onChange={handleChange('otherContractType')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="otherContractType"
              label="¿Qué tipo de contrato es?"
              name="otherContractType"
              value={insertion.otherContractType || ''}
            />
          )}

          {['temporal', 'obra y servicio', 'autónoma'].includes(
            insertion.contractType
          ) && (
            <TextField
              style={{ marginBottom: 20 }}
              onChange={handleChange('contractDuration')}
              required
              fullWidth
              id="contractDuration"
              label="Duración del contrato"
              name="contractDuration"
              value={insertion.contractDuration || ''}
            />
          )}

          <FormControl fullWidth style={{ marginBottom: 20 }} required>
            <InputLabel id="workingDay">Modalidad de trabajo</InputLabel>
            <Select
              labelId="workingMode"
              id="workingMode"
              value={insertion.workingMode}
              onChange={event => {
                setInsertion(prevState => ({
                  ...prevState,
                  workingModeOther: ''
                }))
                handleChange('workingMode')(event)
              }}
              fullWidth
            >
              {Object.keys(workingModes).map(mode => (
                <MenuItem key={mode} value={mode}>
                  {workingModes[mode]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {insertion.workingMode === 'OTHER' && (
            <TextField
              style={{ marginTop: 0, marginBottom: 20 }}
              onChange={handleChange('workingModeOther')}
              margin="normal"
              required
              fullWidth
              id="workingModeOther"
              label="Otra modalidad de trabajo"
              name="workingModeOther"
              value={insertion.workinModeOther}
            />
          )}

          <FormControl fullWidth style={{ marginBottom: 20 }} required>
            <InputLabel id="workingDay">Duración de la jornada</InputLabel>
            <Select
              labelId="workingDay"
              id="workingDay"
              value={insertion.workingDay || ''}
              onChange={handleChange('workingDay')}
              fullWidth
            >
              <MenuItem value={'Completa 40h'}>Completa 40h</MenuItem>
              <MenuItem value={'other'}>Detallar horas</MenuItem>
            </Select>
          </FormControl>

          {insertion.workingDay === 'other' && (
            <TextField
              onChange={handleChange('workingDayOther')}
              margin="normal"
              required
              fullWidth
              id="workingDayOther"
              label="Detallar horas (semanales)"
              name="workingDayOther"
              value={insertion.workingDayOther || ''}
            />
          )}

          <Box py={2} direction="column" fullWidth>
            <InputLabel id="incorporationDate">
              Fecha de incorporación *
            </InputLabel>
            <FormControl fullWidth style={{ marginBottom: 20 }} required>
              <Box py={2}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={insertion.incorporationDate || null}
                  onChange={date => handleDate(date)}
                  id="incorporationDate"
                />
              </Box>
            </FormControl>
            {insertion.incorporationDate &&
              Config.oldPromoDates[profile.promo] && (
                <>
                  <FormHelperText>
                    Tiempo invertido en buscar empleo:{' '}
                    <em>
                      {getDateDiff(
                        insertion.incorporationDate,
                        new Date(
                          Config.oldPromoDates[profile.promo].lastDayOfPromotion
                        )
                      )}{' '}
                      días
                    </em>
                  </FormHelperText>
                </>
              )}
          </Box>

          <TextField
            onChange={handleChange('insertionComments')}
            multiline
            variant="outlined"
            margin="normal"
            fullWidth
            id="insertionComments"
            label="Comentarios"
            name="insertionComments"
            rows={4}
            helperText="Por favor, indica aquí el nombre de tu puesto"
            value={insertion.insertionComments || ''}
          />
        </Box>
      </Container>
      {errors && (
        <Alert style={{ margin: '1rem 0' }} severity="error">
          Completa todos los campos, por favor!
        </Alert>
      )}
      <DialogActions>
        <Button onClick={hideModal} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary">
          Guardar
        </Button>
      </DialogActions>
    </form>
  )
}

export default EditInsertion
