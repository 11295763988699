import React from 'react'
import { useModal } from 'react-modal-hook'

import Matcher from './partials/Matcher'
import Estado from './partials/Estado'
// import Empresa from './partials/Empresa'
import Oportunidad from './partials/Oportunidad'
import Insertar from './partials/Insertar'
import Empresa from '../../employer/dashboard/ProfileDetail'

import { Container, Tabs, Tab, Toolbar, Paper, Tooltip, Fab, Box, Dialog } from '@material-ui/core'
import TabPanel from 'components/UI/TabPanel'
import { Settings, Business, BusinessCenter, PlusOne } from '@material-ui/icons'
import SmilingGirl from 'components/UI/CustomIcons/SmilingGirl'


const EmployerDetail = ({ promo, employers, employer, adalabers, update }) => {
  const [tab, setTab] = React.useState(0)
  const ApplicantList = adalabers.filter(ada => ada.accepted && ada.accepted.includes(employer.id) )
  
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Dialog
        className="modalWithDatepicker"
        maxWidth="xs"
        open={open}
        onExited={onExited}
        onClose={hideModal}
      >
        <Insertar
          hideModal={hideModal}
          employer={employer}
          update={update}
          adalabers={adalabers}
        />
      </Dialog>
    ),
    [employer, adalabers, update]
  )

  if (employer.noOpportunities) return (
    <Container maxWidth="sm">
      <Paper square>
        <Box p={2}>
          <Empresa opp={{...employer, employer: employer.id}} />
        </Box>
      </Paper>
    </Container>
  )
  return (
    <Container>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" p={2} width="100%">
          <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} centered={true} variant="fullWidth">
            <Tab label="Oportunidad" icon={<BusinessCenter />} />
            <Tab label="Estado" icon={<Settings />} />
            <Tab label={employer.name} icon={<Business />} />
            <Tab label="Matcher" icon={<SmilingGirl />} disabled={ApplicantList.length < 1} />
          </Tabs>
          <Box>
            <Tooltip title="Insertar">
              <Fab color="primary" onClick={() => showModal()} aria-label="insertar">
                <PlusOne />
              </Fab>
            </Tooltip>
          </Box>
        </Box>
      </Toolbar>

      <TabPanel value={tab} index={0}>
        <Oportunidad employer={employer} />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <Estado employer={employer} updateData={update} adalabers={adalabers} />
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <Container maxWidth="sm">
          <Paper square>
            <Box p={2}>
              <Empresa opp={employer} />
            </Box>
          </Paper>
        </Container>
      </TabPanel>
      
      <TabPanel value={tab} index={3}>
        <Paper square>
          <Matcher employers={employers} update={update} employer={employer} adalabers={ApplicantList} />
        </Paper>
      </TabPanel>
    </Container>
  )
}

export default EmployerDetail