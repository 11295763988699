import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'reactfire'

import { AppBar, Toolbar, Typography, IconButton, Box } from '@material-ui/core';
import { PowerSettingsNew, Home } from '@material-ui/icons';
import SmilingGirl from 'components/UI/CustomIcons/SmilingGirl'

import AdminNavTabs from './AdminNavTabs'
import EmployerNavTabs from './EmployerNavTabs'
// TODO: make this work
// import { useUserView } from '../../hooks'

const Navigation = ({user}) => {
  // const [ userView ] = useUserView()
  const [ userView ] = [false]
  const auth = useAuth()

  if (!user) return null
  return (
    <AppBar position="static" className={!!userView ? 'ada' : null}>
      <Toolbar variant="dense">
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <IconButton
              className="menuButton"
              color="inherit"
              aria-label="Menu"
              component={Link}
              to='/'
            >
              <>
                {
                  !!userView || <Home />
                }

                {
                  (userView === 'ada') && <SmilingGirl />
                }
              </>
            </IconButton>
            <Box display="flex" alignItems="center" style={{ whiteSpace: 'nowrap', marginRight: 8 }}>
              {
               user && user.profile && user.profile.logo ? (
                  <>
                    <Typography variant="h5" color="inherit" component="h1" className="grow" style={{ position: 'relative', top: '2px' }}>
                      EmMa & 
                    </Typography>
                    <img src={user.profile.logo} style={{ width: 80, marginLeft: 6 }} alt=""/>
                  </>
                ) : (
                  <Typography variant="h5" color="inherit" component="h1" className="grow" style={{ position: 'relative', top: '2px' }}>
                    EmMa 
                  </Typography>
                )
              }
            </Box>

            {/* <Divider orientation="vertical" color="secondary" /> */}

            {
              user && user.isAdmin && <AdminNavTabs />
            }

            {
              user && user.isEmployer && <EmployerNavTabs />
            }
          </Box>

          <Box>
            <IconButton
              onClick={() => auth.signOut()}
              color="inherit"
              key="signout"
            >
              <PowerSettingsNew />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation
