import React from 'react'
import ReactDOM from 'react-dom'
import {
  FirebaseAppProvider,
  SuspenseWithPerf,
} from 'reactfire'
import { config } from './fb/firebase'
import AdaSpinner from 'components/UI/AdaSpinner'

import 'index.css'
import App from 'components/App'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './config/theme'
import 'helpers/stopConsole'

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={config}>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <SuspenseWithPerf fallback={<AdaSpinner />}>
        <App />
      </SuspenseWithPerf>
    </MuiThemeProvider>
  </FirebaseAppProvider>,
  document.getElementById('root')
)
