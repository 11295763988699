import React from 'react'
import { Link } from 'react-router-dom'
import { db } from 'fb'
import { useModal } from 'react-modal-hook'
import moment from 'moment'

import Contratar from './Contratar';

import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Dialog, Tooltip } from '@material-ui/core'
import { Phone, Undo } from '@material-ui/icons'
import HandShake from 'components/UI/CustomIcons/HandShake'
import SmilingGirl from 'components/UI/CustomIcons/SmilingGirl'

const Matcher = ({employers, employer, adalabers, update}) => {
  const [activeAdalaber, setActiveAdalaber] = React.useState(null)

  const setAndOpen = (id) => {
    const adalaber = adalabers.find(adalaber => adalaber.id === id)
    Promise.resolve(setActiveAdalaber(adalaber)).then(() => showModal())
  }
  
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Dialog
        className="modalWithDatepicker"
        maxWidth="xs"
        open={open}
        onExited={onExited}
        onClose={hideModal}
      >
        <Contratar
          hideModal={hideModal}
          employer={employer}
          update={update}
          adalaber={activeAdalaber}
        />
      </Dialog>
    ),
    [activeAdalaber, employer, update]
  );

  const removeInserted = (employer, adalaber) => async event => {
    event.preventDefault();
    await db.removeAdalaberFromEmployer(employer, adalaber)
  };

  return <TableContainer>
      <Table size={adalabers.length > 8 ? 'small' : 'medium'}>
        <TableHead>
          <TableRow style={{ fontWeight: 'bold' }}>
            <TableCell>
              <strong>Adalaber</strong>
            </TableCell>
            <TableCell>Contactada</TableCell>
            <TableCell>Insertada</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {adalabers.map(adalaber => {
            const { fullName, contacted, id } = adalaber;
            const isInserted = adalaber.employer || adalaber.employerOutOfNetwork;
            return <TableRow hover={true} key={fullName} style={{ cursor: 'pointer' }}>
                <TableCell component="th" scope="row">
                  {isInserted && <IconButton disabled style={{ marginRight: '.5em' }}>
                      <SmilingGirl />
                    </IconButton>}
                  {fullName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {contacted && contacted.includes(employer.id) && <Phone />}
                </TableCell>

                <TableCell>
                  {isInserted && (
                    <>
                      <span>
                        {
                          adalaber.employer && employers.find(emp => emp.id === adalaber.employer).name
                        }{' '}

                        {
                          adalaber.employerOutOfNetwork && adalaber.employerOutOfNetwork
                        }{' '}
                      </span>
                      {
                        adalaber.incorporationDate && (
                          <span>{moment(adalaber.incorporationDate).format('DD/MM/YYYY')}</span>
                        )
                      }
                    </>
                  )}
                </TableCell>

                <TableCell component="th" scope="row">
                  <Tooltip title="Insertar">
                    {!isInserted ? <IconButton onClick={() => setAndOpen(id)} aria-label="Insertar">
                        <HandShake />
                      </IconButton> : <IconButton onClick={removeInserted(employer, adalaber)} aria-label="Insertar">
                        <Undo />
                      </IconButton>}
                  </Tooltip>
                  <IconButton component={Link} to={`/admin/adalabers/${adalaber.id}`} aria-label="Ver">
                    <SmilingGirl />
                  </IconButton>
                </TableCell>
              </TableRow>;
          })}
        </TableBody>
      </Table>
    </TableContainer>;
}

export default Matcher