import { createMuiTheme } from '@material-ui/core/styles';
export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#14d9c4',
      main: '#0bb8a5',
      dark: '#099d8d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F1D45A',
      light: '#FAED73',
    },
    error: {
      main: 'rgba(255,0,0,1)',
      light: 'rgba(255,0,0,0.6)'
    } 
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    h1: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 'lighter',
      fontSize: '2.618em',
      color: 'var(--adalabText)'
    },
    h2: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 'lighter',
      fontSize: '2em',
      color: 'var(--adalabText)'
    },
    h3: {
      fontFamily: 'Bangers, Impact, sans-serif',
      fontSize: '1.8em',
      color: 'var(--adalabBlue)'
    },
    h4: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 'lighter',
      fontSize: '1.618em',
      color: 'var(--adalabText)'
    },
    h5: {
      fontFamily: 'Bangers, Impact, sans-serif',
      fontSize: '1.4em',
      color: 'var(--adalabBlue)'
    },
    h6: {
      fontFamily: 'Bangers, Impact, sans-serif',
      fontSize: '1.2em',
      color: 'var(--adalabBlue) !important'
    },
    subtitle: {
      fontFamily: 'Bangers, Impact, sans-serif',
      fontSize: '1.618em',
      color: 'var(--adalabBlue)'
    },
    body1: {
      color: 'var(--adalabText)',
      fontWeight: 'lighter',
    },
    body2: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 'lighter',
      color: 'var(--adalabText)'
    }
  },
  overrides: {
    MuiTableBody: {
      root: {
        overflow: 'visible'
      }
    },
    MuiCard: {
      root: {
        height: '100%'
      }
    },
    MuiCardHeader: {
      root: {
        padding: '16px 16px 0'
      }
    },
    MuiListItem: {
      root: {
        color: 'var(--adalabText)'
      }
    },
    MuiFormControlLabel: {
      label: {
        opacity: '.8',
        fontSize: '1em',
        lineHeight: 1
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.8rem',
        lineHeight: '1.2em'
      }
    },
    MuiRadio: {
      root: {
        padding: '10px'
      }
    },
    MuiCheckbox: {
      root: {
        padding: 8,
        transform: 'scale(.8)'
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: '12px'
      }
    },
    MuiFormLabel: {
      root: {
        opacity: '.8',
        lineHeight: '1.2'
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: 'lighter'
      },
      secondary: {
        wordBreak: 'break-word'
      }
    },
    MuiTableCell: {
      head: {
        fontSize: '.8em',
        lineHeight: 1.3
      }
    },
    MuiListItemIcon: {
      alignItemsFlexStart: {
        minWidth: 38,
        transform: 'scale(.8)'
      }
    },
    MuiDivider: {
      vertical: {
        minHeight: 40,
        margin: '0 1em 0 2em'
      }
    }
  }
});