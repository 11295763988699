import React, { useState, useEffect } from 'react';
import { db } from 'fb';
import { useSnackbar } from 'notistack';
import { DialogContent, DialogActions, FormControl, FormLabel, Button, Select, MenuItem, InputLabel, FormHelperText, TextField } from '@material-ui/core'
import DatePicker from 'react-datepicker';

const ContratarFromAdalaber = ({ update, employers, adalaber, hideModal, onUnsubscribe = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [employerName, setEmployerName] = useState('')
  const [activeEmployer, setActiveEmployer] = useState(null)
  const [employerOutOfNetwork, setEmployerOutOfNetwork] = useState(null)
  const [employerPartner, setEmployerPartner] = useState(adalaber.employerPartner || null)
  const [fecha, setFecha] = useState(adalaber.incorporationDate || '')

  useEffect(() => {
    if (adalaber.employerOutOfNetwork) {
      if (adalaber.employerIsPartner) {
        setEmployerName("Partner");
        setEmployerPartner(adalaber.employerOutOfNetwork);
      } else {
        setEmployerName("Otros")
        setEmployerOutOfNetwork(adalaber.employerOutOfNetwork);
      }
    }
    if (adalaber.employerPartner) {
      setEmployerName("Partner")
    }
    if (adalaber.employer) {
      const employer  = employers.find(emp => emp.id === adalaber.employer)
      setEmployerName(employer.name)
    }
  }, []);

  
  /* eslint-disable */
  useEffect(() => {
    console.log(`Active employer changed to ${employerName}`);
    const employer = employers.find(emp => emp.name === employerName)
    setActiveEmployer(employer)
  }, [employerName])
  /* eslint-enable */

  const onSubmit = async event => {
    event.preventDefault();
    let name

    if (employerName == "Otros") {
      await db.updateAdalaber(adalaber.id, { employerOutOfNetwork, })
      name = employerOutOfNetwork
    } else if (employerName == "Partner") {
      await db.updateAdalaber(adalaber.id, { employerPartner, })
      name = employerPartner
    } else {
      await db.insertAdalaberInEmployer(activeEmployer, adalaber)
      name = activeEmployer.name
    }
    
    await db.updateAdalaber(adalaber.id, { 'incorporationDate': fecha })
    
    enqueueSnackbar(`Insertada ${adalaber.fullName} correctamente en ${name}`, { variant: 'success' });
    hideModal()
  };


  const handleEmployerChange = async (e) => {
    await Promise.resolve(setEmployerName(e.target.value))
  }

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <FormControl>
          <InputLabel id="employer-select">Empresa</InputLabel>
          <Select
            labelId="employer-select"
            id="demo-simple-select-helper"
            value={employerName}
            onChange={handleEmployerChange}
          >
            <MenuItem value="">
              <em>Ninguno</em>
            </MenuItem>
            {
              adalaber.accepted && adalaber.accepted.map(accepted => (
                  <MenuItem value={accepted}>{accepted}</MenuItem>
              ))
            }
            <MenuItem value="Partner">
              <em>Empresa colaboradora</em>
            </MenuItem>
            <MenuItem value="Otros">
              <em>Empresa externa</em>
            </MenuItem>
          </Select>
          <FormHelperText>Elige empresa en que insertar a la Adalaber</FormHelperText>
        </FormControl>

        {
          employerName === 'Otros' && (
            <TextField
              style={{ marginTop: '2em' }}
              value={employerOutOfNetwork}
              onChange={(e) => setEmployerOutOfNetwork(e.target.value)}
              variant="outlined"
              fullWidth id="employerOutOfNetwork" label="Empresa externa" autoFocus />
          )
        }

        {
          employerName === 'Partner' && (
            <TextField
              style={{ marginTop: '2em' }}
              value={employerPartner}
              onChange={(e) => setEmployerPartner(e.target.value)}
              variant="outlined"
              fullWidth id="employerPartner" label="Empresa colaboradora" autoFocus />
          )
        }

        <FormControl
          component="fieldset"
          style={{ marginTop: '2em', marginBottom: '1em' }}
        >
          <FormLabel component="legend" htmlFor="incorporationDate" style={{ marginBottom: '.5em' }}>
            Fecha
          </FormLabel>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={fecha}
            onChange={date => setFecha(date)}
            id="incorporationDate"
          />
          <FormHelperText>Elige la fecha de inserción de la Adalaber</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal} color="secondary">
          Cancelar
        </Button>
        {adalaber.incorporationDate &&
          <Button onClick={onUnsubscribe} color="primary">
            Desinsertar
          </Button>
        }
        <Button type="submit" color="primary">
          Guardar
        </Button>
      </DialogActions>
    </form>
  )
}

export default ContratarFromAdalaber