import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

//prod
const onlyProd = true
const isNetlify = false

//developm,emnt
// const onlyProd = false
// const isNetlify = true

// La variable onlyProd asegura que podamos usar solamente la base de datos de producción
// La variable isNetlify fuerza que podamos usar solamente la base de datos de prueba, en emma-pruebas.netlify.com
// Esto se puede hacer mejor, dejo aquí un TODO

const prodConfig = {
  apiKey: 'AIzaSyB-6FW2snuEIre0TWn-CYWlOx3excO3hFA',
  authDomain: 'matcher-platform.firebaseapp.com',
  databaseURL: 'https://matcher-platform.firebaseio.com',
  projectId: 'matcher-platform',
  storageBucket: 'matcher-platform.appspot.com',
  messagingSenderId: '847749105158',
  appId: '1:847749105158:web:bc85960e40e2afdca62587'
}

// eslint-disable-next-line no-undef
var devConfig = {
  apiKey: 'AIzaSyCTJF4x9YKYwY2mh72UsQmz0tlAgQuLzI0',
  authDomain: 'emma-demo-env.firebaseapp.com',
  projectId: 'emma-demo-env',
  storageBucket: 'emma-demo-env.appspot.com',
  messagingSenderId: '924407171545',
  appId: '1:924407171545:web:688a66d9bdd8b6e17ef1c8'
}

export const config = isNetlify
  ? devConfig
  : process.env.NODE_ENV === 'production' || onlyProd === true
  ? prodConfig
  : devConfig
if (window.location.href.includes('localhost') || isNetlify) console.log(config)

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()

export default firebase

export { auth, db, storage }
