import React from 'react'
import { useFirestore, useUser } from 'reactfire'
import styled from 'styled-components'
import { Avatar, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import shrek from 'components/UI/Random/Shrek.jpg'
import { Edit } from '@material-ui/icons'
import { useModal } from 'react-modal-hook'
import AdaAvatar from './AdaAvatar'

export const EditLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;

  transition: opacity 250ms ease;
  opacity: 0;
  background: rgba(0,0,0,.75);

  &:hover {
    opacity: 1;
  }
`

const OurAvatar = styled(Avatar)`
  width: 140px;
  height: 140px;
`

const Pic = styled.div`
  position: relative;
  overflow: hidden;
  width: 140px;
  height: 140px;
  border-radius: 100%;
`

export default function ProfilePic({profile}) {
  const [avatar, setAvatar] = React.useState('')
  const user = useUser()
  const id = profile.id || user.uid
  const userRef = useFirestore().collection('adalabers').doc(id)

  const setAvatarAlt = (av) => setAvatar(av)
  
  const saveAvatar = async () => {
    await userRef.update({
      avatar,
      modifiedOn: new Date()
    })

  }

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Dialog
        maxWidth="md"
        open={open}
        onExited={onExited}
        onClose={hideModal}
        style={{ padding: '1em' }}
      >
        <DialogContent>
          <AdaAvatar src={profile.avatar} saveAvatarImg={setAvatarAlt} maxSize={10000000} />
        </DialogContent>

        <DialogActions>
          <Button onClick={hideModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={async () => {
            await saveAvatar()
            hideModal()
          }} color="primary">
            OK!
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [user, saveAvatar]
  )

  return (
    <Pic>
      <OurAvatar alt={profile.fullName} src={profile.avatar ? profile.avatar : shrek} />
      <EditLink onClick={() => showModal(true)}>
        <Edit />
      </EditLink>
    </Pic>
  )
}
