import React from 'react'
import { usePromo } from 'fb/db'
import { promos } from 'config'
import { Select, MenuItem } from '@material-ui/core';

export default function PromoSet() {
  const [promo, setPromo] = usePromo()
  return (
    <Select
      value={promo}
      onChange={e => setPromo(e.target.value)}
      inputProps={{ name: 'promo', }}
      style={{ minWidth: 150, marginRight: '1em' }}
    >
      <MenuItem value={'all'}>
        Todas
      </MenuItem>
      {promos.map(option => (
        <MenuItem value={option} key={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  )
}
