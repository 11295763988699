import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const TooltipWithList = ({ list }) => (
  <Tooltip
    title={
      <ul className="tooltip">
        {list.map(a => (
          <li key={`list-${Math.random()}-${a}`}>{a}</li>
        ))}
      </ul>
    }
    disableHoverListener={list.length === 0}
    placement="top"
  >
    <Typography>{list.length}</Typography>
  </Tooltip>
);

TooltipWithList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TooltipWithList;
