import React from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'
import * as routes from 'constants/routes'
import { useOpps } from 'fb/db'

import { Container, Box } from '@material-ui/core'
import Dashboard from './Dashboard'
import Offers from './Offers'
import OfferDetail from './OfferDetail'

export default function Employer() {
  const opps = useOpps()
  const areThereOpps = opps.length > 0
  return (
    <Container maxWidth="xl" p={4}>
      <Box py={4}>
        <Switch>
          <Route exact path={'/'} render={()=> <Dashboard areThereOpps={areThereOpps} />} />
          <Route exact path={routes.OFFERS} component={() => <Offers opps={opps} />} />
          <Route path={`${routes.OFFERS}/:id`} component={OfferDetail} />
        </Switch>
      </Box>
    </Container>
  )
}
