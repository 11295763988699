import React from 'react';
import { SvgIcon } from '@material-ui/core'


const Studies = () => (
  <SvgIcon>
    <svg viewBox="0 -31 512 512"xmlns="http://www.w3.org/2000/svg"><path d="M421 138.602V255c0 48.602-84.902 75-165 75S91 303.602 91 255V138.602c0-8.102 6.598-15 15-15h300c8.402 0 15 6.898 15 15zm0 0" fill="#444"/><path d="M421 138.602V255c0 48.602-84.902 75-165 75V123.602h150c8.402 0 15 6.898 15 15zm0 0" fill="#292929"/><path d="M512 105c0 6.3-3.898 12-9.602 14.098l-241 90c-1.796.601-3.597.902-5.398.902s-3.602-.3-5.398-.902l-241-90v-28.2l241-90C252.398.301 254.199 0 256 0s3.602.3 5.398.898l241 90C508.102 93 512 98.7 512 105zm0 0" fill="#6e6e6e"/><path d="M512 105c0 6.3-3.898 12-9.602 14.098l-241 90A16.92 16.92 0 01256 210V0c1.8 0 3.602.3 5.398.898l241 90C508.102 93 512 98.7 512 105zm0 0" fill="#5a5a5a"/><path d="M271 105c0 8.398-6.598 15-15 15H30v222.598l29.098 87.601c2.703 7.797-1.5 16.5-9.297 18.899-8.102 2.703-16.5-1.5-18.899-9.301L30 437.102C28.8 444.3 22.5 450 15 450c-8.402 0-15-6.602-15-15V105c0-8.402 6.598-15 15-15h241c8.402 0 15 6.598 15 15zm0 0" fill="#fdbf00"/></svg>
  </SvgIcon>
)

export default Studies