import React from 'react'
import firebase from 'fb/firebase'
import Markdown from 'components/UI/Markdown'
import styled from 'styled-components'
import { getTheDate } from 'hooks'
import { getEmployer } from 'config/utils'
import { useSession } from 'fb/auth'
import { useAllOpportunities } from 'fb/db'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { useCopy } from 'hooks'
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { BackBtn } from 'components/UI/common'
import { getURLLink } from 'config/utils'
import ProfilePic from './ProfilePic'
import { useModal } from 'react-modal-hook'
import { IconFlagES, IconFlagUK } from 'material-ui-flags'
// import InvitarASlack from './InvitarASlack'
import EditInsertion from './EditInsertion'

const AdaList = styled(List)`
  border-left: 1px solid gainsboro;
  margin: 0.5em 0 !important;
`
const AdaListItem = styled(ListItem)`
  padding: 4px 8px;
`

export default function Profile({ setToggleEdit, ada, adaView }) {
  const user = useSession()
  const copy = useCopy()
  const employers = useAllOpportunities()

  const id = ada ? ada : user.uid
  const profileRef = useFirestore()
    .collection('adalabers')
    .doc(id)
  const profile = useFirestoreDocData(profileRef)

  const inserted = !!profile.incorporationDate
  const newAppUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://localhost:8586'
      : 'https://emma2.adalab.es'

  const [token, setToken] = React.useState('')
  React.useEffect(() => {
    const functions = firebase.functions()
    var createCustomToken = functions.httpsCallable('createCustomToken')

    createCustomToken({ userUid: user.uid })
      .then(result => {
        setToken(result.data.customToken)
      })
      .catch(error => console.log('ERROR', error))
  }, [])

  const [showModal, hideModal] = useModal(({ in: open, onExited }) => {
    return (
      <Dialog
        maxWidth="sm"
        open={open}
        onExited={onExited}
        onClose={hideModal}
        className="modalWithDatepicker"
        scroll="body"
      >
        <BackBtn color="white" onClick={hideModal}>
          <Close />
        </BackBtn>

        <EditInsertion hideModal={hideModal} uid={id} />
      </Dialog>
    )
  }, [])
  const loMostramos = user.isAdmin && !adaView

  const labelEmployer =
    !!profile.employer || !!profile.employerOutOfNetwork
      ? `en ${
          !!profile.employer && !profile.employerOutOfNetwork
            ? getEmployer(profile.employer, employers).name
            : ''
        }${
          !!profile.employerOutOfNetwork ? profile.employerOutOfNetwork : ''
        } con fecha ${getTheDate(profile.incorporationDate).toLocaleDateString(
          'es-ES'
        )}`
      : ''
  return (
    <>
      <Box align="center">
        <ProfilePic profile={profile} />

        <Typography style={{ marginTop: 20 }} component="h1" variant="h3">
          {profile.fullName}
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          onClick={() => user.isAdmin && copy(profile.email)}
        >
          {profile.email}
        </Typography>
        {
          <FormControlLabel
            control={
              <Switch
                checked={!!inserted}
                onClick={() => showModal()}
                name="Insertion form"
              />
            }
            label={`Insertada ${labelEmployer}`}
          />
        }

        <Box mt={4} mb={2}>
          <Divider />
        </Box>

        {profile.new && (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.location.replace(`${newAppUrl}?token=${token}`)
            }
          >
            <Typography variant="h5">Por favor, rellena tu perfil</Typography>
          </div>
        )}
      </Box>

      <List>
        {profile.shortBio && (
          <ListItem disableGutters>
            <ListItemText
              disableTypography
              primary={<Typography>Relato profesional</Typography>}
              secondary={<Markdown>{profile.shortBio}</Markdown>}
            />
          </ListItem>
        )}

        {profile.careerProjection && (
          <ListItem disableGutters>
            <ListItemText
              primary="Proyección"
              secondary={profile.careerProjection}
            />
          </ListItem>
        )}

        {profile.preferredCompanies && (
          <ListItem disableGutters>
            <ListItemText
              primary="Tipo de compañía preferido"
              secondary={profile.preferredCompanies}
            />
          </ListItem>
        )}

        {loMostramos && profile.apprenticeshipContract && (
          <ListItem disableGutters>
            <ListItemText
              primary="¿Puede hacer contrato en prácticas?"
              secondary={
                profile.apprenticeshipContract ===
                'Ninguno de los anteriores en los últimos 5 años'
                  ? 'No'
                  : 'Sí'
              }
            />
          </ListItem>
        )}

        {profile.studies && (
          <ListItem disableGutters>
            <ListItemText
              primary="Nivel de estudios"
              secondary={profile.studies}
            />
          </ListItem>
        )}

        {profile.studiesDescription && (
          <ListItem disableGutters>
            <ListItemText
              primary="Descripción de estudios"
              secondary={profile.studiesDescription}
            />
          </ListItem>
        )}

        {profile.englishLevel && (
          <ListItem disableGutters>
            <ListItemText
              primary="Nivel de Inglés"
              secondary={profile.englishLevel}
            />
          </ListItem>
        )}

        {profile.otrosIdiomas && (
          <ListItem disableGutters>
            <ListItemText
              primary="Otros idiomas"
              secondary={profile.otrosIdiomas}
            />
          </ListItem>
        )}

        {profile.additionalLocations && (
          <ListItem disableGutters>
            <ListItemText
              primary="Otras localizaciones"
              secondary={profile.additionalLocations}
            />
          </ListItem>
        )}

        {profile.movilidad && (
          <ListItem disableGutters>
            <ListItemText
              primary="Posibilidad para viajar"
              secondary={profile.movilidad}
            />
          </ListItem>
        )}

        {profile.comments && (
          <ListItem disableGutters>
            <ListItemText
              primary="Otros conocimientos"
              secondary={profile.comments}
            />
          </ListItem>
        )}

        {profile.cvURL && (
          <ListItem
            disableGutters
            component="a"
            target="_blank"
            href={getURLLink(profile.cvURL)}
            rel="noopener noreferrer"
          >
            <ListItemText
              primary={
                <>
                  <span>Curriculum Vitae</span>
                  <IconFlagES style={{ left: '0.25rem', height: 12 }} />
                </>
              }
              secondary="Descargar PDF"
            />
          </ListItem>
        )}

        {profile.cvEnURL && (
          <ListItem
            disableGutters
            component="a"
            target="_blank"
            href={getURLLink(profile.cvEnURL)}
            rel="noopener noreferrer"
          >
            <ListItemText
              primary={
                <>
                  <span>Curriculum Vitae</span>
                  <IconFlagUK style={{ left: '0.25rem', height: 12 }} />
                </>
              }
              secondary="Descargar PDF"
            />
          </ListItem>
        )}

        {profile.portafolio && (
          <ListItem
            disableGutters
            component="a"
            target="_blank"
            href={getURLLink(profile.portafolio)}
            rel="noopener noreferrer"
          >
            <ListItemText primary="Portafolio" secondary={profile.portafolio} />
          </ListItem>
        )}
      </List>

      <br />
      {profile.softskills && (
        <>
          <Typography>Soft skills</Typography>
          <AdaList>
            {profile.softskills.map(skill => (
              <AdaListItem key={skill}>
                <ListItemText secondary={skill} />
              </AdaListItem>
            ))}
          </AdaList>
          <br />
        </>
      )}

      {profile.experiencia && (
        <>
          <Typography>Experiencia</Typography>
          <AdaList>
            {profile.experiencia.map(exp => (
              <AdaListItem key={exp}>
                <ListItemText secondary={exp} />
              </AdaListItem>
            ))}
          </AdaList>
          <br />
        </>
      )}

      {(profile.github || profile.linkedin || profile.tel) && (
        <>
          <Typography>Perfiles</Typography>
          <AdaList>
            {['github', 'linkedin'].map(item => {
              if (profile[item])
                return (
                  <ListItem
                    key={item}
                    component="a"
                    target="_blank"
                    href={getURLLink(profile[item])}
                    alignItems="flex-start"
                  >
                    <ListItemText
                      primary={`${item}`}
                      secondary={profile[item]}
                    />
                  </ListItem>
                )
              return null
            })}

            {profile.tel && (
              <ListItem alignItems="flex-start">
                <ListItemText primary={`Teléfono`} secondary={profile.tel} />
              </ListItem>
            )}
          </AdaList>
        </>
      )}
    </>
  )
}
