import React, { useState, useEffect } from 'react';
import { useSession } from 'fb/auth';
import { useOpps } from 'fb/db';

import { useLocation, Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import { Tabs, Tab } from '@material-ui/core';
// Dialog, DialogContent, DialogActions, Button, TextField
// import { useModal } from 'react-modal-hook'

const EmployerNavTabs = () => {
  const user = useSession()
  const { pathname } = useLocation()
  const areThereOpps = useOpps().length > 0
  
  const EmployerNavTabs = [routes.LANDING, routes.OFFERS]

  const getTabIndex = (route, tabs) => tabs.includes(route)
      ? tabs.indexOf(route)
      : route.includes(routes.OFFERS) ? 1
      : 0

  const [tabIndex, setTabIndex] = useState(getTabIndex(pathname, EmployerNavTabs))

  /* eslint-disable */
  useEffect(() => {
    if (!user) return setTabIndex(0)
    // const tabs = user.isAdmin ? AdminTabs : NormalTabs
    return setTabIndex(getTabIndex(pathname, EmployerNavTabs))
  }, [pathname])
  /* eslint-enable */

  if (!user || !areThereOpps) return null

  return (

    <Tabs
      onChange={(event, tabIndex) => setTabIndex(tabIndex)}
      value={tabIndex}
      indicatorColor="secondary"
    >
      <Tab component={Link} to={routes.LANDING} label="Dashboard" />
      <Tab disabled={!areThereOpps} component={Link} to={routes.OFFERS} label="Ofertas" />
    </Tabs>
  )
}

export default EmployerNavTabs