import React from 'react'
import { useAdalabersInOffer, useEmployer } from 'fb/db'
import { useSession } from 'fb/auth'
// import { useFirestore, useFirestoreDocData } from 'reactfire'
import { useParams } from 'react-router-dom'
import SingleOffer from './partials/SingleOffer'
import { Paper, Typography, Container, Box, Grid } from '@material-ui/core'
import AdalabersList from './AdalabersList'

const OfferDetail = () => {
  const user = useSession()
  let { id : OfferID } = useParams()
  // let offerRef = useFirestore().doc(`opportunities/${OfferID}`)
  const offer = useEmployer(OfferID)
  
  const adalabers = useAdalabersInOffer(OfferID, 'all')
  
  if (!offer || !OfferID) return null

  const logo = user.profile && user.profile.logo ? user.profile.logo : offer.logo

  return (
    <Container maxWidth="xl">
      <Grid
        container
        style={{  height: 'calc(100vh - 32px - 32px - 48px)' }}
        spacing={2}

        direction="row"
        justify="center"
        alignItems="stretch"
      >
        <Grid item xs={3} style={{ height: '100%' }} >
          <Paper style={{ height: '100%', overflow: 'scroll' }}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={4}>
              {
               logo && (
                  <img style={{ maxWidth: 120 }} src={logo} alt="" />
                )
              }

              <Typography gutterBottom component="h1" variant="h2">
                {offer.title || offer.name}
              </Typography>
            </Box>

            <SingleOffer showOriginal opp={offer} />
          </Paper>
        </Grid>

        {
          adalabers && (
            <Grid item xs={9} style={{ height: '100%' }}>
              <AdalabersList adalabers={adalabers} offer={offer} OfferID={OfferID} />
            </Grid>
          )
        }
      </Grid>
    </Container>
  )
}

export default OfferDetail