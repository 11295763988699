import moment from 'moment'

const workingModes = {
  REMOTE: '100% remoto',
  ON_SITE: '100% presencial',
  SEMI_PRESENTIAL: 'Semipresencial',
  TEMPORARY_REMOTE: 'Remoto situación sanitaria',
  OTHER: 'Otro'
}

const insertionNotification = ({
  student,
  employer,
  employerOutOfNetwork,
  salary,
  contractType,
  contractDuration,
  incorporationDate,
  insertionComments,
  workingDayOther,
  workingMode,
  workingModeOther
}) => {
  const companyName = !employerOutOfNetwork ? employer : employerOutOfNetwork
  const weekHours = !workingDayOther
    ? 'Completa (40h)'
    : `Parcial (${workingDayOther})`
  const duration = !contractDuration ? 'No definido' : contractDuration
  const mode =
    workingMode !== 'OTHER' ? workingModes[workingMode] : workingModeOther

  fetch(
    'https://hooks.slack.com/services/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',
    {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `@channel La alumna _${student}_ ha sido insertada! :rocket:`
            }
          },
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Nombre de la empresa:*\n${companyName}`
              },
              {
                type: 'mrkdwn',
                text: `*Tipo de contrato:*\n${contractType[0].toUpperCase() +
                  contractType.substring(1)}`
              },
              {
                type: 'mrkdwn',
                text: `*Duración del contrato:*\n${duration}`
              },
              {
                type: 'mrkdwn',
                text: `*Duración de la jornada:*\n${weekHours}`
              },
              {
                type: 'mrkdwn',
                text: `*Modalidad de trabajo:*\n${mode}`
              },
              {
                type: 'mrkdwn',
                text: `*Salario bruto anual:*\n${salary}`
              },
              {
                type: 'mrkdwn',
                text: `*Fecha de incorporación:*\n${moment(
                  incorporationDate
                ).format('L')}`
              },
              {
                type: 'mrkdwn',
                text: `*Comentarios:*\n${insertionComments}`
              }
            ]
          }
        ]
      })
    }
  )
}

const newOfferNotification = params => {
  const {
    acceptanceDeadline,
    companyName,
    contractType,
    salaryRange,
    workingMode,
    workingModeOther,
    employerId,
    jobLocation
  } = params

  let jobLocationInfo = workingModes[workingMode]

  if (['ON_SITE', 'SEMI_PRESENTIAL'].includes(workingMode)) {
    jobLocationInfo += ` en ${jobLocation}`
  }

  if (workingMode == 'OTHER') {
    jobLocationInfo = workingModeOther
  }

  const requestData = {
    mode: 'no-cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text:
              '@channel Tenemos nueva oferta en la plataforma de empleo :briefcase:'
          }
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*Nombre de la empresa:*\n${companyName}`
            },
            {
              type: 'mrkdwn',
              text: `*Tipo de contrato:*\n${contractType}`
            },
            {
              type: 'mrkdwn',
              text: `*Salario bruto anual:*\n${salaryRange}`
            },
            {
              type: 'mrkdwn',
              text: `*Modalidad de trabajo:*\n${jobLocationInfo}.`
            }
          ]
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text:
              'Te aconsejamos, cuando apliques a la oferta en Emma, que si rellenas el campo de *_mensaje para la empresa_*, incluyas un mensaje personalizado y no un *_mensaje tipo_*.'
          }
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Tienes hasta el día ${moment(acceptanceDeadline).format(
              'DD/MM [a las] HH:mm'
            )} horas para inscribirte a esta oferta.`
          }
        },
        {
          type: 'actions',
          elements: [
            {
              type: 'button',
              text: {
                type: 'plain_text',
                text: 'Aplicar a la oferta',
                emoji: true
              },
              url: `https://emma.adalab.es/offers/${employerId}`
            }
          ]
        }
      ]
    })
  }

  // #promocion-h-i-bolsadeempleo
  fetch(
    'https://hooks.slack.com/services/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',
    requestData
  )
  // #promocion-jemison-bolsadeempleo
  fetch(
    'https://hooks.slack.com/services/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',
    requestData
  )
  // #promocion-kizzy-bolsadeempleo
  fetch(
    'https://hooks.slack.com/services/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',
    requestData
  )
  // #promocion-lee-bolsadeempleo
  fetch(
    'https://hooks.slack.com/services/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',
    requestData
  )
  // #promocion-mileva-bolsadeempleo
  fetch(
    'https://hooks.slack.com/services/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',
    requestData
  )
}

export { insertionNotification, newOfferNotification }
