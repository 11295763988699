import React from 'react'
import { getURLLink } from '../../../config/utils'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { useSession } from 'fb/auth'
import { Box, Typography, Divider, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { Send, BusinessCenter, LocationOn, People, AllOut } from '@material-ui/icons'
import LinkedIn from '@material-ui/icons/LinkedIn'
import Markdown from 'components/UI/Markdown'

export default function ProfileDetail({opp}) {
  const user = useSession()
  
  const profileRef = useFirestore().collection('employerUsers').doc(opp ? opp.employer : user.uid)
  const profile = useFirestoreDocData(profileRef)
  
  const {
    cif,
    name,
    email,
    location,
    website,
    linkedin,
    logo,
    description,
    type,
    numberOfEmployees,
  } = profile

  return (
    <>
      <Box align="center">
        <Box align="center">
          <img style={{ maxWidth: 140 }} src={logo} alt=""/>
        </Box>

        <Typography style={{ marginTop: 20 }} component="h1" variant="h2" gutterBottom>
          {name}
        </Typography>

        {
          website && (
            <Typography variant="h3">
              <a target="_blank" rel="noopener noreferrer" href={getURLLink(website)} alt="Página web">{website}</a>
            </Typography>
          )
        }

        <br/>
        
        {
          description && (
            <p>
              <Markdown>{profile.description}</Markdown>
            </p>
          )
        }
        <Divider style={{ marginTop: 8 }} />
      </Box>
      <br/>
      
      <List disablePadding>
        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <ListItemText primary="Email" secondary={email} />
        </ListItem>

        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon>
            <BusinessCenter />
          </ListItemIcon>
          <ListItemText primary="CIF" secondary={cif} />
        </ListItem>

        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon>
            <LocationOn />
          </ListItemIcon>
          <ListItemText primary="Ubicación" secondary={location} />
        </ListItem>

        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary="Número de empleados" secondary={numberOfEmployees} />
        </ListItem>

        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon>
            <AllOut />
          </ListItemIcon>
          <ListItemText primary="Tipología de empresa" secondary={type} />
        </ListItem>

        <ListItem component='a' target="_blank" href={getURLLink(linkedin)} disableGutters alignItems="flex-start">
          <ListItemIcon>
            <LinkedIn />
          </ListItemIcon>
          <ListItemText primary="LinkedIn" secondary={linkedin} />
        </ListItem>
      </List>
    </>
  )
}
