import React from 'react'
import PropTypes from 'prop-types'
import { useSession } from 'fb/auth'
import { useAdalabersWithOffer } from 'fb/db'
import { getTheDate } from '../../../hooks'

import { withRouter, Link } from 'react-router-dom'
import * as routes from 'constants/routes'
import * as STATUS from 'constants/status'
import TooltipWithList from '../common/TooltipWithList'
import moment from 'moment'
import 'moment/locale/es'

import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Toolbar,
  Box,
  IconButton,
  Chip
} from '@material-ui/core'

import {
  CheckCircle as CheckCircleIcon,
  Phone as PhoneIcon,
  Work as WorkIcon,
  Visibility,
  Create,
  School
} from '@material-ui/icons'
import { PromoAvatar } from 'components/UI/common'
import NothingToSeeHere from 'components/UI/Illustrations/NothingToSeeHere'

import config from 'config'

const calculateLength = (objects, ids) =>
  objects.filter(o => ids && ids.includes(o.id)).length

export const SimpleList = ({ employers, adalaber }) => {
  const { accepted, contacted, employer } = adalaber
  return (
    <Table className="table">
      <TableHead>
        <TableRow>
          <TableCell>Empresas</TableCell>
          <TableCell>
            Aceptada ({calculateLength(employers, accepted)})
          </TableCell>
          <TableCell>
            Contactada ({calculateLength(employers, contacted)})
          </TableCell>
          <TableCell>Insertada ({employer ? 1 : 0})</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {employers.map(({ name, id }) => (
          <TableRow key={id} hover className="no-pointer">
            <TableCell>{name}</TableCell>
            <TableCell>
              {accepted && accepted.includes(id) ? <CheckCircleIcon /> : null}
            </TableCell>
            <TableCell>
              {contacted && contacted.includes(id) ? <PhoneIcon /> : null}
            </TableCell>
            <TableCell>
              {employer && employer === id ? <WorkIcon /> : null}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

SimpleList.propTypes = {
  employers: PropTypes.arrayOf(PropTypes.object).isRequired,
  adalaber: PropTypes.object.isRequired
}

export class SimpleListWithFilter extends React.Component {
  state = {
    showAll: ''
  }

  handleChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked ? 'checked' : '' })
  }

  render() {
    const { showAll } = this.state
    const { employers, adalaber } = this.props

    if (!employers || !adalaber) return null
    const filteredEmployers = employers.filter(
      e => showAll || STATUS.OPPORTUNITIES_AVAILABLE.includes(e.status)
    )
    return (
      <>
        <Toolbar>
          <form action="" className="search-form">
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAll}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'showAll'
                  }}
                />
              }
              label="Mostrar empresas con proceso completado"
            />
          </form>
        </Toolbar>
        <SimpleList employers={filteredEmployers} adalaber={adalaber} />
      </>
    )
  }
}

SimpleListWithFilter.propTypes = {
  employers: PropTypes.arrayOf(PropTypes.object).isRequired,
  adalaber: PropTypes.object.isRequired
}

export const SimpleTable = withRouter(
  ({
    employers,
    history,
    changeOrder,
    desc,
    orderBy,
    promo,
    adalabers: allAdalabers
  }) => {
    const dir = desc ? 'desc' : 'asc'

    return (
      <Paper>
        <TableContainer>
          <Table className="table">
            <TableHead className="stickyHeader">
              <TableRow>
                {<TableCell />}
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={dir}
                    onClick={changeOrder('name')}
                  >
                    Nombre
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={dir}
                    onClick={changeOrder('status')}
                  >
                    Estado
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'requestDate'}
                    direction={dir}
                    onClick={changeOrder('requestDate')}
                  >
                    Fecha petición
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'acceptanceDeadline'}
                    direction={dir}
                    onClick={changeOrder('acceptanceDeadline')}
                  >
                    Deadline aceptación
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'accepted'}
                    direction={dir}
                    onClick={changeOrder('accepted')}
                  >
                    <Tooltip title="Alumnas que han aceptado">
                      <CheckCircleIcon />
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'contacted'}
                    direction={dir}
                    onClick={changeOrder('contacted')}
                  >
                    <Tooltip title="Alumnas contactadas">
                      <PhoneIcon />
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'adalabers'}
                    direction={dir}
                    onClick={changeOrder('adalabers')}
                  >
                    <Tooltip title="Alumnas contratadas">
                      <WorkIcon />
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {employers.map(employer => {
                const {
                  id,
                  logo,
                  name,
                  status,
                  adalabers = [],
                  acceptanceDeadline,
                  contacted = [],
                  requestDate,
                  noOpportunities,
                  promo,
                  matches
                } = employer

                const adalabersInOffer = useAdalabersWithOffer(id, 'all')
                let ApplicantList =
                  adalabersInOffer.map(ada => ada.fullName) || []

                if (config.isVeryOldPromo(promo)) {
                  ApplicantList =
                    matches &&
                    matches.map(
                      match =>
                        allAdalabers.find(ada => ada.id === match).fullName
                    )
                }

                return (
                  <TableRow
                    key={id}
                    hover
                    // onClick={() => history.push(`${routes.EMPLOYERS}/${id}`)}
                  >
                    <TableCell>
                      {logo && (
                        <img style={{ maxWidth: 80 }} src={logo} alt="" />
                      )}
                    </TableCell>

                    <TableCell>{name}</TableCell>

                    <TableCell>{status}</TableCell>
                    <TableCell>
                      {requestDate &&
                        getTheDate(requestDate).toLocaleString('es-ES')}
                    </TableCell>
                    <TableCell>
                      {acceptanceDeadline &&
                        getTheDate(acceptanceDeadline).toLocaleString('es-ES')}
                    </TableCell>
                    <TableCell>
                      {ApplicantList && (
                        <TooltipWithList list={ApplicantList} />
                      )}
                    </TableCell>
                    <TableCell>
                      <TooltipWithList list={contacted} />
                    </TableCell>
                    <TableCell>
                      <TooltipWithList list={adalabers} />
                    </TableCell>

                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <IconButton
                          color="primary"
                          component={Link}
                          to={`${routes.EMPLOYERS}/${id}`}
                        >
                          <Create />
                        </IconButton>

                        {!noOpportunities && !config.isVeryOldPromo(promo) && (
                          <IconButton
                            color="primary"
                            component={Link}
                            to={`${routes.OFFERS}/${id}`}
                          >
                            <Visibility />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

          {employers.length < 1 && (
            <NothingToSeeHere text="Todavía no hay nada por aquí..." />
          )}
        </TableContainer>
      </Paper>
    )
  }
)

export const TableOfOffers = withRouter(({ employers, history, adalaber }) => {
  const user = useSession()
  if (!user) return null
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nombre</TableCell>
          <TableCell>Estado</TableCell>
          <TableCell>Ubicación del puesto de trabajo</TableCell>
          <TableCell>Nivel de inglés mínimo requerido</TableCell>
          <TableCell>Fecha y hora límite para confirmar envío de CV</TableCell>
          <TableCell>Tu candidatura</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {employers
          .sort((a, b) => {
            return (
              new Date(a.acceptanceDeadline) - new Date(b.acceptanceDeadline)
            )
          })
          .map(
            ({
              id,
              name,
              status,
              opportunityLocation,
              englishLevel,
              acceptanceDeadline
            }) => (
              <TableRow
                key={id}
                hover
                // HERE
                // onClick={() => history.push(`${user.isAdalaber ? routes.OFFERS : routes.ADMINOFFERS}/${id}`)}
                onClick={() => {
                  if (user.isAdalaber)
                    history.push(`${routes.OFFERSADALABER}/${id}`)
                  if (user.isAdmin)
                    history.push(
                      `${routes.OFFERSADALABER}/${id}/${adalaber.id}`
                    )
                }}
                className="TableRow"
                style={{
                  ...(status === STATUS.VALIDATED && {
                    background: 'var(--adaGradientSuperLight)'
                  }),
                  cursor: 'pointer'
                }}
              >
                <TableCell>
                  <strong>{name}</strong>
                </TableCell>
                <TableCell>{status}</TableCell>
                <TableCell>
                  {opportunityLocation && (
                    <Typography variant="h4" style={{ fontSize: '1.2em' }}>
                      {opportunityLocation}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {englishLevel ? (
                    <Chip
                      color="primary"
                      label={englishLevel}
                      data-color={englishLevel}
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>
                  {acceptanceDeadline && (
                    <Box style={{ transform: 'scale(.8)' }}>
                      <Typography variant="h4" style={{ fontSize: '1.4em' }}>
                        {moment(getTheDate(acceptanceDeadline)).format('HH:mm')}
                      </Typography>
                      <Typography variant="body2">
                        {moment(getTheDate(acceptanceDeadline))
                          .locale('es')
                          .format('LL')}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
                <TableCell>
                  {status == 'Cerrado con éxito' ||
                  status == 'Cerrado sin contratación'
                    ? 'Proceso cerrado'
                    : adalaber?.accepted?.includes(id)
                    ? adalaber?.discarded?.includes(id)
                      ? 'Has sido descartada'
                      : 'Inscrita a la oferta'
                    : 'No has aplicado'}
                </TableCell>
              </TableRow>
            )
          )}
      </TableBody>
    </Table>
  )
})
