import React from 'react';
import { SvgIcon } from '@material-ui/core'


const Tools = () => (
  <SvgIcon>
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="m59.86 12.63-7.35 7.35-7.07-1.42-1.42-7.07 7.35-7.35a12.009 12.009 0 0 0 -15.03 12.97 4.045 4.045 0 0 1 -1.21 3.28l-12.13 12.13-2.61 2.61a4.045 4.045 0 0 1 -3.28 1.21 12.009 12.009 0 0 0 -12.97 15.03l7.35-7.35 7.07 1.42 1.42 7.07-7.35 7.35a12.009 12.009 0 0 0 15.03-12.97 4.045 4.045 0 0 1 1.21-3.28l14.74-14.74a4.045 4.045 0 0 1 3.28-1.21 12.009 12.009 0 0 0 12.97-15.03z" fill="#ffcd00"/><path d="m9.25 40.78a11.988 11.988 0 0 1 9.86-3.44 4.045 4.045 0 0 0 3.28-1.21l2.61-2.61 12.13-12.13a4.045 4.045 0 0 0 1.21-3.28 11.982 11.982 0 0 1 12.409-13.349l.621-.621a12.009 12.009 0 0 0 -15.03 12.97 4.045 4.045 0 0 1 -1.21 3.28l-12.13 12.13-2.61 2.61a4.045 4.045 0 0 1 -3.28 1.21 12.009 12.009 0 0 0 -12.97 15.03l1.621-1.621a11.93 11.93 0 0 1 3.489-8.969z" fill="#ddb200"/><path d="m55.1 59.464 5.82 1.456-1.456-5.82a5.981 5.981 0 0 0 -4.364 4.364z" fill="#58595b"/><path d="m53.142 53.142-3.535-.707v5.652l5.493 1.377a5.981 5.981 0 0 1 4.364-4.364l-1.374-5.49h-5.655z" fill="#a7a9ac"/><path d="m14.394 16.393h4v.002h-4z" fill="#d1e7f8" transform="matrix(.707 -.707 .707 .707 -6.791 16.394)"/><path d="m20.636 12.151-4.243-4.243-8.485 8.485 4.243 4.243 2.828-2.828 2.829-2.829z" fill="#005ece"/><path d="m13.565 5.08a6 6 0 0 0 -8.485 8.485l2.828 2.828 8.485-8.485z" fill="#d1d3d4"/><path d="m36.707 28.707-1.414-1.414 4.293-4.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414z" fill="#cca400"/><path d="m23 41a1 1 0 0 1 0-1.414l4.293-4.293 1.414 1.414-4.293 4.293a1 1 0 0 1 -1.414 0z" fill="#cca400"/><path d="m14.981 17.809-2.831 2.831 11.37 11.36 8.48 8.48 17.607 17.607v-5.652z" fill="#d80027"/><path d="m58.087 49.607-17.607-17.607-8.48-8.48-11.36-11.37-2.831 2.831 34.626 34.626z" fill="#f7941d"/><path d="m49.607 52.435 3.535.707-.707-3.535-34.626-34.626-2.828 2.828z" fill="#f15a29"/></svg>
  </SvgIcon>
)

export default Tools