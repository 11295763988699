import React, { useEffect, useState } from 'react'
import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import * as routes from 'constants/routes'
// import { useUserView } from '../../hooks'
import { useSession } from 'fb/auth'
import firebase from 'fb/firebase'
import { useSnackbar } from 'notistack'
import { useModal } from 'react-modal-hook'
import { Container, Box, Grid, Paper, Dialog, Typography } from '@material-ui/core'
import { Edit, Close } from '@material-ui/icons'
import { Editor, BackBtn } from 'components/UI/common'
import { SimpleListWithFilter as EmployerList } from '../admin/employer/List'
import EditProfile from './EditProfile'
import OffersAdalaber from './Offers'
import OfferDetailAdalaber from './OfferDetail'
import Profile from './Profile'
import NewAdalaber from './NewAdalaber'
import SmilingGirl from 'components/UI/CustomIcons/SmilingGirl'
import NothingToSeeHere from 'components/UI/Illustrations/NothingToSeeHere'

export default function Adalaber({adalaber, employers}) {
  const { enqueueSnackbar } = useSnackbar()
  // const [ adaView, setAdaView ] = useUserView()
  const [ adaView, setAdaView ] = [false]
  const user = useSession()
  const [toggleEdit, setToggleEdit] = React.useState(false)
  const params = useParams()
  const history = useHistory()

  const isAdalaber = user.isAdalaber
  const isAdmin = user.isAdmin
  const profile = user.isAdalaber ? user.profile : adalaber
  const isNew = user.isAdalaber ? user.profile.new : false

  const getEmployerList = user.isAdmin ? (list, employers) => employers.filter(employer => list.includes(employer.id)) : [] 
  const getEmployer = user.isAdmin ? (id, employers) => employers.find(e => e.id === id || e.employer === id) : []

  // Modal!
  const route = user.isAdalaber ? `${routes.OFFERSADALABER}/:id/` : `${routes.OFFERSADALABER}/:id/:ada`
  const match = useRouteMatch(route)

  const newAppUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:8586" : "https://emma2.adalab.es";

  if (window.location.href.includes('localhost')) console.log(profile)

  const [token, setToken] = useState("");
  useEffect( () => {
    const functions = firebase.functions();
    // functions.useFunctionsEmulator('http://localhost:5001')
    var createCustomToken = functions.httpsCallable('createCustomToken');

    createCustomToken({ userUid: user.uid })
      .then((result) => {
        setToken(result.data.customToken)
      }).catch(error => console.log("ERROR", error));
  }, []);

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => {

      const onCloseModal = () => {
        hideModal()
        const route = isAdalaber ? '/' : `${routes.ADALABERS}/${profile.id}`
        history.push(route)
      }

      return (
        <Dialog
          maxWidth="sm"
          open={open}
          onExited={onExited}
          onClose={onCloseModal}
          className="align-top"
        >

          <BackBtn color="white" onClick={onCloseModal}>
            <Close />
          </BackBtn>

          <OfferDetailAdalaber profile={profile} id={match.params.id} />
        </Dialog>
      )
    },
    [match, history]
  )
  
  React.useEffect(() => {
    if(match && match.params && match.params.id) showModal()
    return () => console.log()
  }, [match, showModal])

  const closeAndSave = async () => {
    setToggleEdit(false)
    await enqueueSnackbar(
      'Perfil guardado con éxito 👌',
      { variant: 'success' }
    )
  }

  return (
    <Container maxWidth="xl" p={4}>
      {isNew && <NewAdalaber user={user} />}

      <Box p={4}>
        
        <Grid
          container
          spacing={2}

          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item sm="auto" md={toggleEdit ? 5 : 3} style={{ position: 'relative' }}>
            {
              isAdalaber && (
                <Editor onClick={() => window.location.replace(`${newAppUrl}?token=${token}`)} size="small" aria-label="edit" color="primary">
                  {!toggleEdit ? <Edit /> : <Close />}
                </Editor>
              )
            }
            <Paper style={{ height: '100%'}}>
              <Box p={4}>
                {
                  toggleEdit ? (
                    <EditProfile close={closeAndSave} authUser={user} />
                  ) : (
                    <Profile
                      setToggleEdit={() => setToggleEdit(true)}
                      {...(isAdmin && { ada: params.id, adaView:adaView  })}
                    />
                  )
                }

                {
                  isAdmin && (
                    <Editor
                      position="right"
                      color={adaView ? 'primary' : 'secondary'}
                      onClick={() => setAdaView(adaView === 'ada' ? false : 'ada')}
                      // style={adaView ? { background:'var(--adalabBlue)', color: 'white'} : {}}
                    >
                      {
                        !adaView ? <SmilingGirl /> : <Close />
                      }
                    </Editor>
                  )
                }
              </Box>
            </Paper>
          </Grid>
          
          <Grid item sm={'auto'} md={toggleEdit ? 7 : 9}>
            <Paper style={{ height: '100%' }}>
              {
                isAdalaber ? <OffersAdalaber /> : (
                  <Box style={{ position: 'relative' }}>
                  { 
                    adalaber.currentEmployer ? (
                      <Typography variant="h6" component="h2" gutterBottom align="center">
                        {`Insertada en ${
                          adalaber.employer ? getEmployer(adalaber.employer, employers).name : adalaber.employerOutOfNetwork
                        }`}
                      </Typography>
                    ) : adalaber.accepted && adalaber.accepted.length > 0 ? (
                      <>
                        {
                          adaView ? (
                            <OffersAdalaber adalaber={adalaber} employers={employers} />
                          ) : (
                            <EmployerList
                              employers={getEmployerList(adalaber.accepted, employers)}
                              adalaber={adalaber}
                            />
                          )
                        }
                      </>
                    ) : <NothingToSeeHere text="Nada por aquí..." />
                  }
                  </Box>
                )
              }
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
