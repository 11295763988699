import React from 'react'
import { useAuth } from 'reactfire'
import { Link as RouterLink } from 'react-router-dom'

// import { auth } from '../../firebase'
import { Container, Button, TextField, Typography, Link, Box } from '@material-ui/core'

import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'
import FormHeader from 'components/UI/FormHeader'

// const PasswordForgetPage = ({ children }) => <div>{children}</div>


const PasswordForgetForm = props => {
  const [email, setEmail] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const auth = useAuth()
  // const auth = useAuth()

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      await auth.sendPasswordResetEmail(email)
      enqueueSnackbar('Email enviado', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar(
        'Ha habido un error. Comprueba tu correo e inténtalo otra vez.',
        { variant: 'error' }
      )
    }

    setLoading(false)
  }

  return (
    <Container disableGutters component="main" maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="stretch"
        height="100%"
      >
        <FixedLinearProgress isLoading={loading} />
        <FormHeader icon="lock" title="Iniciar Sesión" />
        <form onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Restablecer contraseña
          </Button>

          <Box
            pt={4}
            pb={4}
            display="flex"
            component="footer"
            justifyContent="space-between"
          >
            <Link className="blue" component={RouterLink} to="/signin" variant="body2">
              ¿Ya tienes cuenta? Inicia sesión!
            </Link>
            <Link
              className="blue" 
              component={RouterLink}
              to="/signup"
              variant="body2"
              align="right"
            >
              No tienes cuenta? Crea una aquí!
            </Link>
          </Box>
        </form>
      </Box>
    </Container>
  )
}

const PasswordForgetLink = () => (
  <Typography variant="body1" gutterBottom>
    <Link to='/pw-forget'>¿Olvidaste la contraseña?</Link>
  </Typography>
)

export default PasswordForgetForm

export { PasswordForgetForm, PasswordForgetLink }
