// import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Box, Fab, Avatar, IconButton, LinearProgress } from '@material-ui/core'

export const Editor = styled(Fab)`
  position: absolute;
  top: 0;
  ${({position}) => (position === 'right' || !position) && `
    right: 0;
    transform: translate(-50%, 50%);
  `};
  ${({position}) => position === 'left' && `
    left: 0;
    transform: translate(50%, 50%);
  `}
  z-index: 9;
`

export const BackBtn = styled(IconButton)`
  position: absolute;
  top: 0;
  ${({color}) => color && `color: ${color}`};
  ${({position}) => (position === 'right' || !position) && `
    right: 0;
    transform: translate(-50%, 50%);
  `};
  ${({position}) => position === 'left' && `
    left: 0;
    transform: translate(50%, 50%);
  `}
  z-index: 9;
`

export const BottomEditor = styled(Fab)`
  position: absolute;
  bottom: 0;
  ${({position}) => (position === 'right' || !position) && `
    right: 0;
    transform: translate(-50%, -50%);
  `};
  ${({position}) => position === 'left' && `
    left: 0;
    transform: translate(50%, -50%);
  `}
  z-index: 9;
`

export const SignUpImg = styled.img`
  height: ${(props) => props.large ? '180px' : '130px'};
  margin-bottom: 2em;
  width: auto;
`

export const SignUpBox = styled(Link)`
  flex: 1;
  color: inherit;
  text-decoration: none;

  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;

  position: relative;
  overflow: hidden;

  transition: background-color 250ms ease, border-color 250ms ease;
  &:hover {
    border-color: transparent;
    background: rgba(255, 255, 255, 0.6);
    /* > * { transform: scale(1.01) } */
  }
`;

export const PromoAvatar = styled(Avatar)`
  width: 1.618rem;
  height: 1.618rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--adalabBlue);
  margin-right: 8px;
`


export const CardBar = styled(Box)`
  background: var(--adalabBlue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  h3 {
    color: inherit;
  }
`

export const AdaProgress = styled(LinearProgress)`
  /* position: absolute; */
  top: 0;
  left: 0;
`


export const AdaSectionHeader = styled.div`
  background: var(--adalabBlueTrans);
  background: ${({light}) => light
    ? `var(--adaGradientLight)` : `var(--adaGradientDark);`
  };
  /* ${({light}) => light || `color: white;`} */
  * { color: white; }
  padding-bottom: 0;
  .MuiTab-root { padding: 0; }
  .MuiToolbar-root { align-items: flex-end; }
`